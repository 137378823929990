import React from 'react'
import { Chart } from "react-google-charts";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid } from '@material-ui/core';
var axios = require("axios")

var lastdate = "";

export default function Today(props) {
  const [disabled, setDisabled] = React.useState(true);
  const data = [[
    { type: 'string', id: 'Type' },
    { type: 'date', id: 'Start' },
    { type: 'date', id: 'End' },
  ]]

  var breakamount = 0;

  var dailystamps = props.dailystamps;
  var todaydate;
  var firstend;
  var lastend;
  var lastid = 0;
  for (let i = 0; i < dailystamps.length; i++) {
    //DO Brakes
    const element = dailystamps[i];
    lastend = element.end;
    if (todaydate == undefined) {
      todaydate = element.start;
      firstend = element.end;
    } else {
      if (todaydate.getFullYear() == element.start.getFullYear() && todaydate.getMonth() == element.start.getMonth() && todaydate.getDate() == element.start.getDate()) {
        lastid++;
        var brake = ["Pause", lastend, dailystamps[i - 1].start];
        breakamount = breakamount + ((dailystamps[i - 1].start - lastend) / 1000);
        data.push(brake)
      }
    }

    props.setBreakA(breakamount);

    if (todaydate.getFullYear() == element.start.getFullYear() && todaydate.getMonth() == element.start.getMonth() && todaydate.getDate() == element.start.getDate()) {
      lastid++;
      var work = ["Arbeit", element.start, element.end];
      data.push(work);
    }
  }

  const [reason, setReason] = React.useState('');
  const handleChange = (event) => {
    var result = window.confirm("Bitte beachte: ein Botengang kann nicht wieder zur Pause gewandelt werden. Die Ansicht hier aktualisiert sich in spätestens 120 Sekunden.");
    if (result == true) {
      setReason(event.target.value);
      if (event.target.value == 1) {
        var data2 = { date: lastdate }
        let url = 'https://' + global.localip + ':3005/changeBreakToErrand/' + props.id;
        axios.post(url, data2)
          .then(response => {
            //console.log(response);
          })
          .catch(e => console.log(e))
      } else {
        var data2 = { date: lastdate, reason: event.target.value }
        let url = 'https://' + global.localip + ':3005/setStampReason/' + props.id;
        axios.post(url, data2)
          .then(response => {
            //console.log(response);
          })
          .catch(e => console.log(e))
      }

    }
  };

  if (data.length <= 1) {
    return (
      <div>
        Keine Daten zum Anzeigen vorhanden.
      </div>
    )
  }
  return (
    <div style={{ height: "300px" }}>
      <Chart
        width={'100%'}
        height={'150px'}
        chartType="Timeline"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
          showRowNumber: true,
          colors: ['#0dda38', '#0dabda'],
        }}
        rootProps={{ 'data-testid': '1' }}
        chartEvents={[
          {
            eventName: 'select',
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper.getChart()
              const selection = chart.getSelection()
              var stamp = data[selection[0].row + 1];

              if (((stamp[2] - stamp[1]) / 1000) < 5) {
                setDisabled(true);
                setReason("");
              } else if (stamp[0] == "Pause") {
                var dateout = stamp[1];
                var year = dateout.getFullYear();
                var month = ("0" + (dateout.getMonth() + 1)).slice(-2);
                var date = ("0" + dateout.getDate()).slice(-2);
                var hours = ("0" + dateout.getHours()).slice(-2);
                var minutes = ("0" + dateout.getMinutes()).slice(-2);
                var seconds = ("0" + dateout.getSeconds()).slice(-2);
                var converteddateout = year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
                lastdate = converteddateout;

                var data2 = { date: converteddateout }
                let url = 'https://' + global.localip + ':3005/getStampReason/' + props.id;
                axios.post(url, data2)
                  .then(response => {
                    setReason(response.data[0].reason);
                  })
                  .catch(e => console.log(e))

                setDisabled(false);
              } else {
                setDisabled(true);
                setReason("");
              }
            },
          },
        ]}
      />
      <Grid container>
        <Grid item xs={3}>
          <TimeToday monthlystamps={props.monthlystamps}></TimeToday>
        </Grid>
        <Grid item xs={6}>
          <SelectReason reason={reason} setReason={setReason} handleChange={handleChange} disabled={disabled}></SelectReason>
        </Grid>
      </Grid>
    </div>
  )
}


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function secondstoHHMMSS(totalSeconds) {
  var sec_num = totalSeconds;
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  seconds = parseInt(seconds);
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes + ':' + seconds;
}

function TimeToday(props) {
  return (<h5>
    Heute gearbeitet: {secondstoHHMMSS(props.monthlystamps[0].seconds)}
  </h5>);
}

function SelectReason(props) {
  const classes = useStyles();

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Pausengrund</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.reason}
          onChange={props.handleChange}
          disabled={props.disabled}
        >
          <MenuItem value={0}>Nicht gesetzt</MenuItem>
          <MenuItem value={1}>Botengang</MenuItem>
          <MenuItem value={2}>Mittagspause</MenuItem>
          <MenuItem value={3}>Raucherpause</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}