import React from 'react';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import Popper from '@material-ui/core/Popper';
import { Timeline, TimelineEvent } from 'react-event-timeline'
import { Paper, Button, IconButton } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Badge from '@material-ui/core/Badge';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

var timeout = true;

export default function InfoDropdown() {
    const [data, setData] = React.useState(undefined);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [invisible, setInvisible] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);

    const handleOpen = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);

        if (Boolean(anchorEl)) {
            var url4 = "https://" + global.localip + ":3005/markAllEventsAsSeen/" + window.loggedinid;

            fetch(url4)
                .then(response => response.json())
                .then(result4 => {
                    
                })
        }
        var url5 = "https://" + global.localip + ":3005/getEvents/" + window.loggedinid;

        fetch(url5)
            .then(response => response.json())
            .then(result4 => {
                setData(result4)
            })
    };

    var unseencount = 0;
    try {
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.seen === 0) {
                unseencount++;
            }
        }
    } catch (error) {

    }

    const open = Boolean(anchorEl);
    if (data === undefined || unseencount === 0) {
        if (!invisible) {
            setInvisible(true);
        }
    } else {
        if (open) {
            if (!invisible) {
                setInvisible(true);
            }
        } else {
            if (invisible) {
                setInvisible(false);
            }
        }
    }

    if (data === undefined) {
        var url4 = "https://" + global.localip + ":3005/getEvents/" + window.loggedinid;
        fetch(url4)
            .then(response => response.json())
            .then(result4 => {
                setData(result4)
            })

        return (<div></div>)
    } else {
        if (timeout === true) {
            timeout = false;
            setTimeout(() => {
                timeout = true;

                var url4 = "https://" + global.localip + ":3005/getEvents/" + window.loggedinid;

                fetch(url4)
                    .then(response => response.json())
                    .then(result4 => {
                        setData(result4);
                    })
            }, 6000);
        }
    }

    return (
        <div>
            <Badge color="secondary" badgeContent={unseencount} invisible={invisible}>
                <IconButton onClick={handleClick} style={{zIndex: 999999}}>
                    <AddAlertIcon style={{fontSize: 60}}></AddAlertIcon>
                </IconButton>
            </Badge>
            <Popper open={open} anchorEl={anchorEl} transition placement={"bottom-end"}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={700}>
                        <Paper>
                            <Timeline>
                                {
                                    data.map(function (dt, i) {
                                        if (i >= 5) {
                                            return (<></>)
                                        }
                                        var c = new Date(dt.inserted_at);
                                        var s = c.getDate() + ". " + (c.getMonth() + 1) + ". " + c.getFullYear() + " " + c.getHours() + ":" + c.getMinutes();
                                        var color;
                                        if (dt.seen === 1) {
                                            color = "#9fbcc2";
                                        } else {
                                            color = "#5ae090"
                                        }
                                        return (
                                            <TimelineEvent title={dt.title}
                                                createdAt={s}
                                                icon={<i className="material-icons md-18">{dt.icon}</i>}
                                                key={i}
                                                iconColor={color}
                                            >
                                                {dt.description}
                                            </TimelineEvent>
                                        )
                                    })
                                }
                            </Timeline>
                            <Button variant="contained" color="primary" onClick={handleOpen} fullWidth>Alle anzeigen</Button>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Paper>
                    <IconButton onClick={handleClose} style={{ position: "absolute", top: "30px", right: "30px" }}  >
                        <CloseIcon fontSize={"large"}></CloseIcon>
                    </IconButton>
                    <Timeline>
                        {
                            data.map(function (dt, i) {
                                var c = new Date(dt.inserted_at);
                                var s = c.getDate() + ". " + (c.getMonth() + 1) + ". " + c.getFullYear() + " " + c.getHours() + ":" + c.getMinutes();
                                var color;
                                if (dt.seen === 1) {
                                    color = "#9fbcc2";
                                } else {
                                    color = "#5ae090"
                                }
                                return (
                                    <TimelineEvent title={dt.title}
                                        createdAt={s}
                                        icon={<i className="material-icons md-18">{dt.icon}</i>}
                                        key={i}
                                        iconColor={color}
                                    >
                                        {dt.description}
                                    </TimelineEvent>
                                )
                            })
                        }
                    </Timeline>
                </Paper>
            </Modal>
        </div>
    )
}
