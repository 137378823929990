import React, { useState } from 'react'
import {
    Calendar,
    momentLocalizer,
} from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Grid from '@material-ui/core/Grid';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const localizer = momentLocalizer(moment);

export default function Calendar2(props) {
    const [dontinclude, setdontinclude] = useState([]);
    var datarequested = props.datarequested;
    var dataaccepted = props.dataaccepted;
    var colors = ["#9de7fb", "#33ffbd", "#DAF7A6", "#FFC300", "#FF5733", "#C70039", "#900C3F"];
    var requestcolor = "#576675";
    var userswithcolors = {};

    var data = [];
    for (let i = 0; i < dataaccepted.length; i++) {
        const element = dataaccepted[i];
        data.push({ title: element.name + " " + element.surname, start: element.startDate, end: element.endDate, allDay: true, resource: "test" });
        if (userswithcolors[element.name + " " + element.surname] === undefined) {
            userswithcolors[element.name + " " + element.surname] = colors[Object.keys(userswithcolors).length];
        }
    }
    for (let i = 0; i < datarequested.length; i++) {
        const element = datarequested[i];
        data.push({ title: element.name + " " + element.surname + " Urlaubsantrag", start: element.startDate, end: element.endDate, allDay: true, resource: "test" })
        if (userswithcolors[element.name + " " + element.surname] === undefined) {
            userswithcolors[element.name + " " + element.surname] = colors[Object.keys(userswithcolors).length];
        }
    }

    const eventStyleGetter = function (event, start, end, isSelected) {
        var backgroundColor = '#' + event.hexColor;
        if (event.title.endsWith("Urlaubsantrag")) {
            backgroundColor = requestcolor;
        } else {
            backgroundColor = userswithcolors[event.title];
        }
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block'
        };
        return {
            style: style
        };
    };

    /*
    DONT INCOUDE Geht Nicht
    console.log(dontinclude);
    var datac = data;
    console.log(datac);
    for (let i = 0; i < dontinclude.length; i++) {
        const element = dontinclude[i];
        for (let j = 0; j < datac.length; j++) {
            const element2 = datac[j];
            if (element2.title.startsWith(element)) {
                datac.splice(j, 1);
                i = 0;
                j = 0;
            }
        }
    }
    console.log(datac);*/
    

    return (
        <div>
            <br></br>
            <h4>Kalendarische Übersicht</h4>
            <Grid container spacing={3}>
                <Grid item md={2} xs={1}>
                    <List component="nav" aria-label="main mailbox folders">
                        {Object.entries(userswithcolors).map(([key, value]) => {
                            return (
                                <ListItem button key={key} onClick={() => {
                                    if (dontinclude.includes(key)) {
                                        var arr1 = dontinclude;
                                        arr1.splice(arr1.indexOf(key), 1);
                                        setdontinclude("");
                                        setdontinclude(arr1);
                                    } else {
                                        var arr2 = dontinclude;
                                        arr2.push(key);
                                        setdontinclude("");
                                        setdontinclude(arr2);
                                    }
                                }}>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon style={{ color: value }}></FiberManualRecordIcon>
                                    </ListItemIcon>
                                    <ListItemText primary={key} />
                                </ListItem>
                            );
                        })}
                    </List>
                </Grid>
                <Grid item md={10} xs={11}>
                    <Calendar
                        localizer={localizer}
                        events={data}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: "800px" }}
                        eventPropGetter={(eventStyleGetter)}
                        views={['month']}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

