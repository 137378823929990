import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TimeTables from "../../components/Graphs/TimeTables.js"
import Charts from "../../components/Graphs/Charts.js";
import CircularProgress from '@material-ui/core/CircularProgress';

import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Lateness from '../../components/Graphs/Lateness.js'
import Today from '../../components/Graphs/Today.js'
import { addDays } from 'date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AlarmIcon from '@material-ui/icons/Alarm';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import WorkOffIcon from '@material-ui/icons/WorkOff';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Saldo from 'components/Graphs/Saldo.js';
import AddIcon from '@material-ui/icons/Add';
import TodayIcon from '@material-ui/icons/Today';
import Holidayoverview from 'components/Graphs/Holidayoverview.js';

var interval;
var loggedin = false;
let results = undefined;
let stamps = [];
let dailystamps = [];
let monthlystamps = [];
let annuallystamps = [];


export default class Dashboard extends React.Component {
  componentWillUnmount() {
    clearInterval(interval);
    interval = undefined;
  }
  componentDidMount() {
    this.fetchandRedoData();
    if (interval === undefined) {
      interval = setInterval(() => {
        if (window.loggedinid !== undefined) {
          this.fetchandRedoData();
        }
      }, 120000);
    }
  }
  render() {

    if (loggedin) {
      return (
        <div>
          <SimpleTabs fetchandRedo={this.fetchonly} doDailyStamps={this.doDailyStamps} doMonthlyStamps={this.doMonthlyStamps} doAnnuallyStamps={this.doAnnuallyStamps}></SimpleTabs>
        </div>
      )
    } else {
      return (
        <div style={{ width: "100%" }}>
          <Box display="flex" flexDirection="column" justifyContent="flex-end">
            <CircularProgress />
          </Box>
        </div>
      )
    }

  }

  fetchonly() {
    let url = 'https://' + global.localip + ':3005/getStamp/' + window.loggedinid
    fetch(url)
      .then(response => response.json())
      .then(result => {
        stamps = [];
        results = result;
        //Stamps to Date Object
        for (let i = 0; i < results.length; i++) {
          const stamp = results[i];
          stamp.stamp = stamp.stamp.replace("T", " ");
          stamp.stamp = stamp.stamp.replace("Z", " ");
          let dateTimeParts = stamp.stamp.split(/[- :]/);

          dateTimeParts[1]--;
          const dateObject = new Date(...dateTimeParts);
          dateObject.setHours(dateObject.getHours() + 2);
          stamps.push({ time: dateObject, inorout: stamp.inorout, reason: stamp.reason });
        }
      })
  }

  fetchandRedoData() {
    let url = 'https://' + global.localip + ':3005/getStamp/' + window.loggedinid
    fetch(url)
      .then(response => response.json())
      .then(result => {
        results = result;
        stamps = [];
        //Stamps to Date Object
        for (let i = 0; i < results.length; i++) {
          const stamp = results[i];
          stamp.stamp = stamp.stamp.replace("T", " ");
          stamp.stamp = stamp.stamp.replace("Z", " ");
          let dateTimeParts = stamp.stamp.split(/[- :]/);

          dateTimeParts[1]--;
          const dateObject = new Date(...dateTimeParts);
          dateObject.setHours(dateObject.getHours() + 2);
          stamps.push({ time: dateObject, inorout: stamp.inorout, reason: stamp.reason });
        }
        //Calculate, draw Graphs
        //Stamps By Day (Every Stamp)
        this.doDailyStamps();
        //Stamp by Month (Every Day)
        this.doMonthlyStamps();
        //Stamps by Year (Every Month)
        this.doAnnuallyStamps();
        //Fore Rerender

        loggedin = true;
        this.forceUpdate();
      })
  }

  doDailyStamps() {
    dailystamps = [];
    var laststamp;
    for (let i = 0; i < stamps.length; i++) {
      const element = stamps[i];
      if(element.time.getTimezoneOffset() == -60){
        element.time.setHours(element.time.getHours()-1);
      }
      if (laststamp === undefined) {
        //First, Check if still stamped in
        if (element.inorout === 1) {
          var startStamp = element.time;
          var endStamp = new Date();
          var seconds = (endStamp - startStamp) / 1000;
          var calculatedStamp = { start: startStamp, end: endStamp, seconds: seconds }
          dailystamps.push(calculatedStamp);
        }
        laststamp = element;
      } else {
        //Get inorout=1
        if (element.inorout === 1) {
          var startStamp = element.time;
          var endStamp = laststamp.time;
          var seconds = (endStamp - startStamp) / 1000;
          var calculatedStamp = { start: startStamp, end: endStamp, seconds: seconds }
          dailystamps.push(calculatedStamp);
        }
        laststamp = element;
      }
    }
  }

  doMonthlyStamps() {
    monthlystamps = [];
    for (let i = 0; i < dailystamps.length; i++) {
      const element = dailystamps[i];
      let date = element.start;
      if (monthlystamps.length === 0) {
        newDay()
      } else {
        var laststamp = monthlystamps[monthlystamps.length - 1].start;
        if (laststamp.getFullYear() === date.getFullYear() && laststamp.getMonth() === date.getMonth() && laststamp.getDate() === date.getDate()) {
          monthlystamps[monthlystamps.length - 1].start = element.start;
          monthlystamps[monthlystamps.length - 1].seconds = monthlystamps[monthlystamps.length - 1].seconds + element.seconds;
        } else {
          newDay();
        }
      }
      function newDay() {
        var startStamp = element.start;
        var endStamp = element.end;
        var secs = element.seconds;
        var calculatedStamp = { start: startStamp, end: endStamp, seconds: secs }
        monthlystamps.push(calculatedStamp);
      }
    }
  }

  doAnnuallyStamps() {
    annuallystamps = [];
    for (let i = 0; i < monthlystamps.length; i++) {
      const element = monthlystamps[i];
      let date = element.start;
      if (annuallystamps.length === 0) {
        newMonth();
      } else {
        var laststamp = annuallystamps[annuallystamps.length - 1].start;
        if (laststamp.getFullYear() === date.getFullYear() && laststamp.getMonth() === date.getMonth()) {
          annuallystamps[annuallystamps.length - 1].start = element.start;
          annuallystamps[annuallystamps.length - 1].seconds = annuallystamps[annuallystamps.length - 1].seconds + element.seconds;
        } else {
          newMonth();
        }
      }
      function newMonth() {
        var startStamp = element.start;
        var endStamp = element.end;
        var secs = element.seconds;
        var calculatedStamp = { start: startStamp, end: endStamp, seconds: secs }
        annuallystamps.push(calculatedStamp);
      }
    }
  }
}


//Tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(styles);

var holidaya = 0;
var weeka = 0;


function dezimaltoHHMM(params) {
  var decimalTime = parseFloat(params);
  decimalTime = decimalTime * 60 * 60;
  var hours = Math.floor((decimalTime / (60 * 60)));
  decimalTime = decimalTime - (hours * 60 * 60);
  var minutes = Math.floor((decimalTime / 60));
  decimalTime = decimalTime - (minutes * 60);
  var seconds = Math.round(decimalTime);
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return ("" + hours + ":" + minutes);

}

function SimpleTabs(props) {
  const classes = useStyles();

  var [holiday, setHoliday] = React.useState(0);

  function setLate(prop) {
    try {
      document.getElementById("dashb-late").innerHTML = prop;
    } catch (error) {

    }
  }

  function setWeek(params) {
    try {
      if (weeka !== undefined) {
        document.getElementById("dashb-week").innerHTML = dezimaltoHHMM(params) + " / " + weeka;
      }
    } catch (error) {

    }
  }

  function setWeekA(params) {
    weeka = params;
  }

  function setHolidayA(params) {
    holidaya = params;
  }

  function setBreakA(params) {
    try {
      document.getElementById("dashb-break").innerHTML = secondstoHHMMSS(params);
    } catch (error) {

    }
  }

  function setMissed(params) {
    try {
      document.getElementById("dashb-missed").innerHTML = params;
    } catch (error) {

    }
  }

  if (holiday === 0) {
    let url = 'https://' + global.localip + ':3005/getWorktimesbyId/' + window.loggedinid;
    fetch(url)
      .then(response => response.json())
      .then(responseJSON => {
        if (responseJSON.length === 0) {
          alert("Bitte sprich einen Admin an. Deine Arbeitszeiten müssen noch gesetzt werden.")
        } else {

          var url = "https://" + global.localip + ":3005/getNotDeclinedHolidaysbyId/" + window.loggedinid;
          fetch(url)
            .then(response => response.json())
            .then(result2 => {
              var useddaysbyyear = {};

              function getDates(startDate, stopDate) {
                var dateArray = new Array();
                var currentDate = startDate;
                while (currentDate <= stopDate) {
                  dateArray.push(new Date(currentDate));
                  currentDate = addDays(currentDate, 1);
                }
                return dateArray;
              }

              for (let i = 0; i <= result2.length; i++) {
                var element;
                var select;
                if (i === result2.length) {
                  select = {
                    selection0: {
                      startDate: null,
                      endDate: null,
                      key: 'selection0'
                    },
                  };
                } else {
                  element = JSON.parse(result2[i].holidayobject);
                  select = element.selection0;
                }
                const start = new Date(select.startDate);
                const end = new Date(select.endDate);
                var alldates = getDates(start, end);
                for (let i = 0; i < alldates.length; i++) {
                  const element = alldates[i];
                  if (global.WorkingDaysAt(element, window.loggedinid).includes(element.getDay())) {
                    //HIER
                    if (useddaysbyyear[element.getFullYear()] = undefined) {
                      useddaysbyyear[element.getFullYear()] = useddaysbyyear[element.getFullYear()] + 1;
                    } else {
                      useddaysbyyear[element.getFullYear()] = 1;
                    }
                  }
                }
              }

              setHoliday(useddaysbyyear);
            })

        }
      })
  }

  return (
    <>
      <Grid container>
        <GridItem xs={12} sm={12} md={12} style={{ zIndex: "0" }}>
          <h1>Dashboard</h1>
          <h3>Übersicht der Arbeitszeiten</h3>
        </GridItem>
        <GridItem xs={4} style={{ zIndex: "0" }}>
          <Card style={{ minHeight: "70px" }}>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <TodayIcon></TodayIcon>
              </CardIcon>
              <h4 className={classes.cardCategory}>Arbeitsstunden Heute:</h4>
              <h4 className={classes.cardTitle}>{secondstoHHMMSS(Math.round(monthlystamps[0].seconds))}</h4>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={4} style={{ zIndex: "0" }}>
          <Card style={{ minHeight: "70px" }}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <HourglassFullIcon></HourglassFullIcon>
              </CardIcon>
              <h4 className={classes.cardCategory}>Arbeitsstunden diese Woche:</h4>
              <h4 className={classes.cardTitle} id="dashb-week"></h4>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={4} style={{ zIndex: "0" }}>
          <Card style={{ minHeight: "70px" }}>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <WorkOffIcon />
              </CardIcon>
              <h4 className={classes.cardCategory}>Deine Pausenzeit heute:</h4>
              <h4 className={classes.cardTitle} id="dashb-break"></h4>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={6} style={{ zIndex: "0" }}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <AddIcon></AddIcon>
              </CardIcon>
              <Saldo monthlystamps={monthlystamps} id={window.loggedinid} classes={classes}></Saldo>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={6} style={{ zIndex: "0" }}>
          <Card style={{ minHeight: "70px" }}>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <DateRangeIcon></DateRangeIcon>
              </CardIcon>
              <h4 className={classes.cardCategory}>Genommene Urlaubstage:</h4>
              <h4 className={classes.cardTitle}>{Object.entries(holiday).map(([key, value]) => {
                var date = new Date();
                date.setFullYear(key);
                var amount = global.HolidayAAt(date, window.loggedinid)
                return (
                  <div key={key}>{key}: {value.toString()} / {amount}</div>
                );
              })}</h4>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} style={{ zIndex: "0" }}>
          <Card>
            <CardHeader color="danger">
              <h4 className={classes.cardTitle} style={{ color: "white" }}>Kalenderwochen</h4>
            </CardHeader>
            <CardBody>
              <Charts dailystamps={dailystamps} monthlystamps={monthlystamps} annuallystamps={annuallystamps} setWeek={setWeek}></Charts>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} style={{ zIndex: "0" }}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitle} style={{ color: "white" }}>Tabelle</h4>
            </CardHeader>
            <CardBody>
              <TimeTables dailystamps={dailystamps} monthlystamps={monthlystamps} annuallystamps={annuallystamps}></TimeTables>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} style={{ zIndex: "0" }}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitle} style={{ color: "white" }}>Verspätungen / Fehlzeiten</h4>
            </CardHeader>
            <CardBody>
              <Lateness monthlystamps={monthlystamps} id={window.loggedinid} setLate={setLate} setWeekA={setWeekA} setHolidayA={setHolidayA} setMissed={setMissed}></Lateness>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} style={{ zIndex: "0" }}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitle} style={{ color: "white" }}>Tagesübersicht <WhichDay dailystamps={dailystamps}></WhichDay></h4>
            </CardHeader>
            <CardBody>
              <Today dailystamps={dailystamps} monthlystamps={monthlystamps} id={window.loggedinid} setBreakA={setBreakA}></Today>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} style={{ zIndex: "0" }}>
          <Card>
            <CardHeader color="rose">
              <h4 className={classes.cardTitle} style={{ color: "white" }}>Urlaubspläne</h4>
            </CardHeader>
            <CardBody>
              <Holidayoverview userid={window.loggedinid}></Holidayoverview>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={6} style={{ zIndex: "0" }}>
          <Card style={{ minHeight: "70px" }}>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <AlarmIcon></AlarmIcon>
              </CardIcon>
              <h4 className={classes.cardCategory}>Anzahl Verspätungen diesen Monat:</h4>
              <h4 className={classes.cardTitle} id="dashb-late"></h4>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={6} style={{ zIndex: "0" }}>
          <Card style={{ minHeight: "70px" }}>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <NotInterestedIcon />
              </CardIcon>
              <h4 className={classes.cardCategory}>Verpasste Arbeitstage dieses Jahr</h4>
              <h4 className={classes.cardTitle} id="dashb-missed"></h4>
            </CardHeader>
          </Card>
        </GridItem>
      </Grid>
    </>
  )
}


function WhichDay(props) {
  if (props.dailystamps !== undefined && props.dailystamps[0] != undefined) {
    return <>für den {props.dailystamps[0].start.getDate()}. {props.dailystamps[0].start.getMonth() + 1}. {props.dailystamps[0].start.getFullYear()}</>;
  }
  return <></>;
}

function secondstoHHMMSS(totalSeconds) {
  var sec_num = totalSeconds;
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  seconds = parseInt(seconds);
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes + ':' + seconds;
}