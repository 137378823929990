import React from 'react'
//
import {
    ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import Card from 'components/Card/Card';

export default function Charts(props) {
    const monthlystamps = props.monthlystamps;
    const currentweek = getWeekNumber(new Date());

    const data = []
    var lastweek = "";
    for (let i = 0; i < monthlystamps.length; i++) {
        const start = monthlystamps[i].start;
        const week = getWeekNumber(start);
        let label = "KW " + week[1] + " " + week[0];
        let hours = monthlystamps[i].seconds / 3600;
        var days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
        var day = days[start.getDay()]
        hours = hours.toFixed(2)
        if (label != lastweek) {
            var object = { name: label, Montag: 0, Dienstag: 0, Mittwoch: 0, Donnerstag: 0, Freitag: 0, Samstag: 0, Sonntag: 0 }
            if (day === "Montag") {
                object.Montag = hours;
            } else if (day === "Dienstag") {
                object.Dienstag = hours;
            } else if (day === "Mittwoch") {
                object.Mittwoch = hours;
            } else if (day === "Donnerstag") {
                object.Donnerstag = hours;
            } else if (day === "Freitag") {
                object.Freitag = hours;
            } else if (day === "Samstag") {
                object.Samstag = hours;
            } else if (day === "Sonntag") {
                object.Sonntag = hours;
            }
            data.push(object)
        } else {
            if (day === "Montag") {
                data[data.length - 1].Montag = hours;
            } else if (day === "Dienstag") {
                data[data.length - 1].Dienstag = hours;
            } else if (day === "Mittwoch") {
                data[data.length - 1].Mittwoch = hours;
            } else if (day === "Donnerstag") {
                data[data.length - 1].Donnerstag = hours;
            } else if (day === "Freitag") {
                data[data.length - 1].Freitag = hours;
            } else if (day === "Samstag") {
                data[data.length - 1].Samstag = hours;
            } else if (day === "Sonntag") {
                data[data.length - 1].Sonntag = hours;
            }
        }
        lastweek = label;
    }

    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        var total = 0;
        for (const key in element) {
            if (key != "name") {
                const element2 = element[key];
                total = total + parseFloat(element2);
            }
        }
        total = total.toFixed(2);
        data[i].Insgesamt = total;
        if (element.name === ("KW " + currentweek[1] + " " + currentweek[0])) {
            props.setWeek(total);
        }
    }

    if (data.length === 0) {
        return (
            <div>
                Keine Daten zum Anzeigen vorhanden.
            </div>
        )
    }

    return (
        <>
            <ResponsiveContainer width="100%" minHeight="300px">
                <ComposedChart margin={{ top: 20, right: 30, left: 20, bottom: 5 }} data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip content={CustomTooltip} />
                    <Legend />
                    <Bar dataKey="Montag" stackId="a" fill="#017351" />
                    <Bar dataKey="Dienstag" stackId="a" fill="#03C383" />
                    <Bar dataKey="Mittwoch" stackId="a" fill="#AAD962" />
                    <Bar dataKey="Donnerstag" stackId="a" fill="#FBBF45" />
                    <Bar dataKey="Freitag" stackId="a" fill="#EF6A32" />
                    <Bar dataKey="Samstag" stackId="a" fill="#ED0345" />
                    <Bar dataKey="Sonntag" stackId="a" fill="#7e0c90" />
                    <Line type="monotone" dataKey="Insgesamt" stroke="#8884d8" strokeWidth={3} />
                </ComposedChart>
            </ResponsiveContainer>
        </>
    )
}

function CustomTooltip(props) {
    var payload = props.payload;
    if (!props.active) return null;
    return (
        <Card style={{ padding: "10px", paddingTop: "0px" }}>
            <h5 style={{margin: "0px"}}>{props.label}</h5>
            {payload.map(function (item, i) {
                return <p key={i} style={{ margin: "0px", color: item.color }}>{item.name}: {dezimaltoHHMM(item.payload[item.name])}</p>
            })}
        </Card>
    )
}

function dezimaltoHHMM(params) {
    var decimalTime = parseFloat(params);
    decimalTime = decimalTime * 60 * 60;
    var hours = Math.floor((decimalTime / (60 * 60)));
    decimalTime = decimalTime - (hours * 60 * 60);
    var minutes = Math.floor((decimalTime / 60));
    decimalTime = decimalTime - (minutes * 60);
    var seconds = Math.round(decimalTime);
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return ("" + hours + ":" + minutes);
  
  }

function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    return [d.getUTCFullYear(), weekNo];
}