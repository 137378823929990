import MaterialTable from 'material-table';
import React from 'react'

export default function AddWorkAdmin() {
    const [data, setData] = React.useState(undefined);
    if (data == undefined) {
        var url = "https://" + global.localip + ":3005/getrequestedWork/";
        fetch(url)
            .then(response => response.json())
            .then(result => {
                setData(result);
            })
        return "Loading...";
    }


    var tabledata = [];

    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        var obj = {};
        obj.start = element.start;
        obj.end = element.end;
        obj.user_id = element.user_id;
        obj.id = element.id;
        tabledata.push(obj);
    }


    return (
        <div>
            <MaterialTable
                columns={[
                    { title: 'Name', field: 'name', render: rowData => <UserData rowData={rowData}></UserData> },
                    { title: 'Anfang', field: 'start', type: 'datetime' },
                    { title: 'Ende', field: 'end', type: 'datetime' }
                ]}
                data={tabledata}
                title="Anträge über den Nachtrag von Arbeitszeiten."
                actions={[
                    {
                        icon: 'check',
                        tooltip: 'Annehmen',
                        onClick: (event, rowData) => {
                            var url = "https://" + global.localip + ":3005/grantrequestedWork/" + rowData.id;
                            fetch(url)
                                .then(response => response.json())
                                .then(result => {
                                    var url = "https://" + global.localip + ":3005/getrequestedWork/";
                                    fetch(url)
                                        .then(response => response.json())
                                        .then(result => {
                                            setData(result);
                                        })
                                })
                        }
                    },
                    {
                        icon: 'clear',
                        tooltip: 'Ablehnen',
                        onClick: (event, rowData) => {
                            var url = "https://" + global.localip + ":3005/declinerequestedWork/" + rowData.id;
                            fetch(url)
                                .then(response => response.json())
                                .then(result => {
                                    var url = "https://" + global.localip + ":3005/getrequestedWork/";
                                    fetch(url)
                                        .then(response => response.json())
                                        .then(result => {
                                            setData(result);
                                        })
                                })
                        }
                    }
                ]}
            />
        </div>
    )
}


function UserData(props) {
    const [data, setData] = React.useState(undefined);
    if (data == undefined) {
        var url = "https://" + global.localip + ":3005/getUserbyId/" + props.rowData.user_id;
        fetch(url, { 
          method: 'POST', 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ sessionid: localStorage.getItem("sessionid") }) 
        })
            .then(response => response.json())
            .then(result => {
                setData(result);
            })
        return "Loading User";
    }
    return data[0].name + " " + data[0].surname;
}
