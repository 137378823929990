import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react'
import { addDays } from 'date-fns';

var axios = require("axios");

export default function Saldo(props) {
    const [open, setOpen] = React.useState(false);
    const [fetched, setFetched] = React.useState(false);
    const [allowedmissingdays, setAllowedmissingdays] = React.useState([]);
    var monthlystamps = props.monthlystamps;
    var id = props.id;
    if (global.currentWorktimeObject == undefined) {
        return ("Arbeitszeiten setzen...");
    }

    if (monthlystamps === undefined || id === undefined) {
        return (
            <div>
                Loading
            </div>
        )
    }

    var lastweek = undefined;
    var lastyear = undefined;
    var workedamountbyweek = []; //Array with index=year, second index = weekno. Contains object with seconds & date
    var allowedmissingdaysc = allowedmissingdays;
    if (allowedmissingdays.length == 0 && !fetched) {
        var url = "https://" + global.localip + ":3005/getNotDeclinedHolidaysbyId/" + id;
        fetch(url)
            .then(response => response.json())
            .then(result2 => {
                function getDates(startDate, stopDate) {
                    var dateArray = new Array();
                    var currentDate = startDate;
                    while (currentDate <= stopDate) {
                        dateArray.push(new Date(currentDate));
                        currentDate = addDays(currentDate, 1);
                    }
                    return dateArray;
                }
                for (let i = 0; i < result2.length; i++) {
                    var element;
                    var select;
                    element = JSON.parse(result2[i].holidayobject);
                    select = element.selection0;

                    const start = new Date(select.startDate);
                    const end = new Date(select.endDate);
                    var alldates = getDates(start, end);
                    for (let i = 0; i < alldates.length; i++) {
                        const element = alldates[i];

                        if (global.WorkingDaysAt(element, id).includes(element.getDay())) {
                            var topush = element.getDate() + "." + (element.getMonth() + 1) + "." + element.getFullYear();
                            allowedmissingdaysc.push(topush);
                        }
                    }
                }


                var url3 = "https://" + global.localip + ":3005/getMissingReasons/" + id;
                axios.get(url3)
                    .then(response => {
                        var data = response.data;
                        for (let i = 0; i < data.length; i++) {
                            const element = new Date();
                            var split = data[i].day.split(".");
                            element.setFullYear(split[2]);
                            element.setMonth(split[1] - 1);
                            element.setDate(split[0]);
                            if (global.WorkingDaysAt(element, id).includes(element.getDay())) {
                                var topush = element.getDate() + "." + (element.getMonth() + 1) + "." + element.getFullYear();
                                if (!allowedmissingdaysc.includes(topush)) {
                                    allowedmissingdaysc.push(topush);
                                }
                            }
                        }

                        var url4 = "https://feiertage-api.de/api/?jahr=";
                        var url4_2 = "&nur_land=HH";
                        
                        var startyear = monthlystamps[monthlystamps.length - 1].start.getFullYear();
                        var endyear = monthlystamps[0].end.getFullYear();
                        var did = 0;
                        for (let i = startyear; i <= endyear; i++) {
                            fetch(url4 + i + url4_2)
                                .then(response => response.json())
                                .then(result4 => {
                                    for (const key in result4) {
                                        if (Object.hasOwnProperty.call(result4, key)) {
                                            const holiday = result4[key];
                                            var date = new Date(holiday.datum);
                                            if (global.WorkingDaysAt(date, id).includes(date.getDay())) {
                                                var topush = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
                                                if (!allowedmissingdaysc.includes(topush)) {
                                                    allowedmissingdaysc.push(topush);
                                                }
                                            }
                                        }
                                    }
                                    did++;

                                    if (did == (endyear - startyear + 1)) {
                                        setFetched(true);
                                        setAllowedmissingdays([...allowedmissingdaysc]);
                                    }
                                })
                        }

                    })
                    .catch(e => console.log(e))
            })
    }

    for (let i = 0; i < monthlystamps.length; i++) {
        const element = monthlystamps[i];
        var weekmonday = new Date(element.start);
        while (weekmonday.getDay() != 1) {
            weekmonday.setDate(weekmonday.getDate() - 1);
        }
        var year = element.start.getFullYear();
        var weekno = getWeekNumber(element.start)[1];
        if (lastweek !== weekno || lastyear !== year) {
            lastweek = weekno;
            lastyear = year;
            //New Week
            if (workedamountbyweek[year] == undefined) {
                workedamountbyweek[year] = [];
            }
            workedamountbyweek[year][weekno] = { seconds: element.seconds, date: weekmonday, worktimeobject: global.WorktimeObjectAt(weekmonday, id), daysworked: 1, daysarray: [] };
        } else {
            workedamountbyweek[year][weekno].seconds = workedamountbyweek[year][weekno].seconds + element.seconds;
            workedamountbyweek[year][weekno].daysworked = workedamountbyweek[year][weekno].daysworked + 1;
            workedamountbyweek[year][weekno].date = weekmonday;
        }
        if (i == monthlystamps.length - 1) {
            workedamountbyweek[year][weekno].firstweek = true;
        } else if (i == 0) {
            workedamountbyweek[year][weekno].lastweek = true;
        }
        workedamountbyweek[year][weekno].daysarray.push(element.start);
    }

    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    var totalSaldo = 0;
    for (let i = 0; i < workedamountbyweek.length; i++) {
        const year = workedamountbyweek[i];
        if (year != undefined) {
            for (let j = 0; j < year.length; j++) {
                const week = year[j];
                if (week !== undefined) {
                    if (week.firstweek != undefined) {
                        var amounttowork = week.worktimeobject[week.worktimeobject.length - 2].week;
                        var workeachday = amounttowork / (week.worktimeobject.length - 2);
                        var shouldworkdays = week.worktimeobject.length - 2;
                        var daysarray = week.daysarray;

                        var amountdaysnottowork = shouldworkdays;
                        for (let i = 0; i < week.worktimeobject.length - 1; i++) {
                            const element = week.worktimeobject[i];
                            for (let k = 0; k < daysarray.length; k++) {
                                const element2 = daysarray[k];
                                if (element2.getDay() === parseInt(element.day)) {
                                    amountdaysnottowork--;
                                }
                            }
                        }

                        for (let i = 1; i <= amountdaysnottowork; i++) {
                            amounttowork = amounttowork - workeachday;
                        }

                        totalSaldo = totalSaldo - amounttowork;
                        totalSaldo = totalSaldo + week.seconds / 3600;
                    } else {
                        totalSaldo = totalSaldo + week.seconds / 3600 - week.worktimeobject[week.worktimeobject.length - 2].week;
                    }

                    var amounttowork = week.worktimeobject[week.worktimeobject.length - 2].week;
                    var workeachday = amounttowork / (week.worktimeobject.length - 2);
                    if(!isFinite(workeachday)){
                        workeachday = amounttowork;
                    }
                    for (let i = 0; i < 7; i++) {
                        const element = new Date(week.date);
                        element.setDate(element.getDate() + i);
                        if (global.WorkingDaysAt(element, id).includes(element.getDay())) {
                            var tocheck = element.getDate() + "." + (element.getMonth() + 1) + "." + element.getFullYear();
                            //console.log(workeachday);
                            if (allowedmissingdays.includes(tocheck)) {
                                totalSaldo = totalSaldo + workeachday;
                            } else if (element.getDate() == 24 && element.getMonth() == 11 || element.getDate() == 31 && element.getMonth() == 11) {
                                totalSaldo = totalSaldo + (workeachday / 2);
                            } else {
                                if(element > today){
                                    totalSaldo = totalSaldo + workeachday;
                                }
                            }
                           // console.log(totalSaldo);
                        }
                    }
                }
            }
        }
    }

    totalSaldo = totalSaldo * 100;
    totalSaldo = Math.round(totalSaldo);
    totalSaldo = totalSaldo / 100;
    if (totalSaldo >= 0) {
        totalSaldo = "+" + totalSaldo;
    } else {
        totalSaldo = totalSaldo;
    }
    if (!fetched) {
        return (
            <>
                <h4 className={props.classes.cardCategory}>Dein Saldo:</h4>
                <h4 className={props.classes.cardTitle} id="dashb-saldo">Laden...</h4>
            </>
        )
    }
    return (
        <>
            <h4 className={props.classes.cardCategory}>Dein Saldo:</h4>
            <h4 className={props.classes.cardTitle} id="dashb-saldo">{totalSaldo >= 0 && <>{dezimaltoHHMM(totalSaldo)}</>}{totalSaldo < 0 && <>-{dezimaltoHHMM(Math.abs(totalSaldo))}</>}<Button variant="outlined" color="primary" onClick={() => { setOpen(true) }}>Details anzeigen</Button></h4>
            <Dialog open={open} onClose={() => { setOpen(false) }} fullWidth>
                <DialogTitle onClose={() => { setOpen(false) }}>
                    Saldo
                    </DialogTitle>
                <DialogContent>
                    <div>
                        {workedamountbyweek.map((year, key) => {
                            if (year === undefined) {
                                return null;
                            }
                            return <div key={key}>{key + ":"}{year.map((prop, key) => {
                                if (prop === undefined) {
                                    return null;
                                }
                                var amounttowork = prop.worktimeobject[prop.worktimeobject.length - 2].week;

                                var workeachday = amounttowork / (prop.worktimeobject.length - 2);
                                if (prop.firstweek != undefined) {
                                    var shouldworkdays = prop.worktimeobject.length - 2;
                                    var daysarray = prop.daysarray;

                                    var amountdaysnottowork = shouldworkdays;
                                    for (let i = 0; i < prop.worktimeobject.length - 1; i++) {
                                        const element = prop.worktimeobject[i];
                                        for (let k = 0; k < daysarray.length; k++) {
                                            const element2 = daysarray[k];
                                            if (element2.getDay() === parseInt(element.day)) {
                                                amountdaysnottowork--;
                                            }
                                        }
                                    }

                                    for (let i = 1; i <= amountdaysnottowork; i++) {
                                        amounttowork = amounttowork - workeachday;
                                    }

                                    totalSaldo = totalSaldo - amounttowork;
                                    totalSaldo = totalSaldo + prop.seconds / 3600;
                                }


                                var datesofweek = [];
                                var week = getWeekNumber(new Date(prop.date))[1];
                                for (let i = 0; i < 7; i++) {
                                    const element = new Date(prop.date);
                                    element.setDate(element.getDate() + i);
                                    if (week == getWeekNumber(element)[1]) {
                                        var topush = element.getDate() + "." + (element.getMonth() + 1) + "." + element.getFullYear();
                                        datesofweek.push(topush);
                                    }
                                }

                                for (let i = 0; i < datesofweek.length; i++) {
                                    const element = datesofweek[i];
                                    var dateparts = element.split(".");
                                    var date = new Date(dateparts[2], parseInt(dateparts[1])-1, dateparts[0]);

                                    if (global.WorkingDaysAt(date, id).includes(date.getDay())) {
                                        if (allowedmissingdays.includes(element)) {
                                            amounttowork = amounttowork - workeachday;
                                        } else if (dateparts[0] == "24" && dateparts[1] == "12" || dateparts[0] == "31" && dateparts[1] == "12") {
                                            amounttowork = amounttowork - (workeachday / 2);
                                        }
                                    }

                                }

                                var difference = toTwoDigitDecimal((prop.seconds / 3600) - amounttowork);
                                if (difference >= 0) {
                                    return <div style={{ height: "30px" }} key={key}>Woche {getWeekNumber(prop.date)[1]}: {dezimaltoHHMM(toTwoDigitDecimal(prop.seconds / 3600))} / {dezimaltoHHMM(amounttowork)} = <p style={{ color: "green", display: "inline-block" }}>+{dezimaltoHHMM(difference)}</p></div>;
                                } else {
                                    return <div style={{ height: "30px" }} key={key}>Woche {getWeekNumber(prop.date)[1]}: {dezimaltoHHMM(toTwoDigitDecimal(prop.seconds / 3600))} / {dezimaltoHHMM(amounttowork)} = <p style={{ color: "red", display: "inline-block" }}>-{dezimaltoHHMM(Math.abs(difference))}</p></div>;
                                }
                            })}</div>;
                        })}
                    </div>
                    <Button fullWidth variant="outlined" color="primary" onClick={() => { setOpen(false) }} style={{ marginTop: "10px" }}>
                        Schließen
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    )
}


function dezimaltoHHMM(params) {
    var decimalTime = parseFloat(params);
    decimalTime = decimalTime * 60 * 60;
    var hours = Math.floor((decimalTime / (60 * 60)));
    decimalTime = decimalTime - (hours * 60 * 60);
    var minutes = Math.floor((decimalTime / 60));
    decimalTime = decimalTime - (minutes * 60);
    var seconds = Math.round(decimalTime);
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }

    return ("" + hours + ":" + minutes);
}

function toTwoDigitDecimal(number) {
    var number1 = number;
    number1 = number1 * 100;
    number1 = Math.round(number1);
    number1 = number1 / 100;
    return number1;
}

function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
}
