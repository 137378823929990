import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import TextField from '@material-ui/core/TextField';
import { Snackbar } from "@material-ui/core";
import GetAvatar from "./GetAvatar";
import MuiAlert from '@material-ui/lab/Alert';
var axios = require("axios");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function UserProfile(props) {
  const [state, setState] = React.useState({ open: false, msg: "Test", severity: "success" });
  var id = props.id;
  if (id === undefined) {
    id = window.loggedinid;
  }
  const classes = useStyles();
  if (window.loggedinadmin === undefined) {
    alert("Du bist nicht eingeloggt.")
    window.hist.push("/admin/dashboard")
    return <></>;
  }
  let url = 'https://' + global.localip + ':3005/getUserbyId/' + id;
  fetch(url, { 
    method: 'POST', 
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ sessionid: localStorage.getItem("sessionid") }) 
  })
    .then(response => response.json())
    .then(result => {
      try {
        document.getElementById("surname").value = result[0].surname;
        document.getElementById("name").value = result[0].name;
        document.getElementById("team").value = result[0].team;
        var created_at = new Date(result[0].created_at);
        var created = created_at.getDate() + ". " + (created_at.getMonth() + 1) + ". " + created_at.getFullYear();
        document.getElementById("created_at").value = created;
      } catch (error) {
        
      }
    })

  function updateProfile() {
    try {
      let url = 'https://' + global.localip + ':3005/updateUserbyId/' + id;
      url = url + "/" + document.getElementById("surname").value + "/" + document.getElementById("name").value + "/" + document.getElementById("team").value;
      fetch(url)
        .then(response => response.json())
        .then(result => {
          setState({ open: true, msg: "Profil erfolgreich geändert", severity: "success" });
        })
    } catch (error) {
      
    }
  }

  function updatePassword() {
    try {
      let url = 'https://' + global.localip + ':3005/updatePassword/' + id;
  
      if (document.getElementById("password1").value === document.getElementById("password2").value) {
        if (document.getElementById("password1").value.length >= 8) {
          var data = { password: document.getElementById("password1").value }
          axios.post(url, data)
            .then(response => {
              setState({ open: true, msg: "Passwort erfolgreich geändert", severity: "success" });
            })
            .catch(e => console.log(e))
        } else {
          setState({ open: true, msg: "Das Passwort ist zu kurz.", severity: "error" });
        }
      } else {
        setState({ open: true, msg: "Die Passwörter stimmen nicht überein.", severity: "error" });
      }
    } catch (error) {
      
    }
  }


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({ open: false, msg: "", severity: "" });
  };

  return (
    <div>
      <Snackbar open={state.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={state.severity} >
          {state.msg}
        </Alert>
      </Snackbar>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Profil bearbeiten</h4>
              <p className={classes.cardCategoryWhite}>Verfollständige dein Profil</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="name"
                    label="Vorname"
                    defaultValue="vorname"
                    autoComplete="current-password"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="surname"
                    label="Nachname"
                    defaultValue="name"
                    autoComplete="current-password"
                  />
                </GridItem>
              </GridContainer>
              <br></br>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="team"
                    label="Abteilung / Organisation"
                    defaultValue="team"
                    autoComplete="current-password"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="created_at"
                    label="Mitglied seit"
                    defaultValue="-"
                    autoComplete="current-password"
                    disabled
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={updateProfile}>Profil aktualisieren</Button>
            </CardFooter>
            <h4 id="profil-info" style={{ display: "none" }}></h4>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <h4 className={classes.cardTitle}>Dein Profilbild</h4>
            <GetAvatar id={id}></GetAvatar>
            <Button
              variant="contained"
              component="label"
              style={{marginTop: "30px"}}
              color="primary"
            >
              Profilbild aktualisieren (max. 100kb)
              <input
                type="file"
                style={{ display: "none" }}
                id="uploadpb"
                accept=".png,.jpg"
                onChange={(e) => {
                  try {
                    var file = document.getElementById("uploadpb").files[0];
  
                    if (file.size < 100000) {
                      const reader = new FileReader();
  
                      reader.readAsDataURL(file)
                      reader.addEventListener("load", function () {
                        var image = new Image();
  
                        image.src = reader.result;
  
                        image.onload = function () {
                          var height = this.height;
                          var width = this.width;
  
                          if (height == width) {
                            var data = { data: reader.result }
                            let url = 'https://' + global.localip + ':3005/updatePic/' + id;
                            axios.post(url, data)
                              .then(response => {
                                setState({ open: true, msg: "Profilbild erfolgreich aktualisiert", severity: "success" });
                                global.hist.push("/admin/user2")
                                global.hist.push("/admin/user")
                              })
                              .catch(e => console.log(e))
                          } else {
                            setState({ open: true, msg: "Dein hochgeladenes Bild muss quadratisch sein.", severity: "error" });
                          }
  
  
                        }
  
  
                      }, false);
                    } else {
                      setState({ open: true, msg: "Die Dateigröße überschreitet die erlaubte Größe", severity: "error" });
                    }
                  } catch (error) {
                    
                  }
                }}
              />
            </Button>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Passwort ändern</h4>
              <p className={classes.cardCategoryWhite}>Hier kannst du dein Passwort bearbeiten.</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="password1"
                    label="Passwort ändern"
                    type="password"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    id="password2"
                    label="Passwort wiederholen"
                    type="password"
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={updatePassword}>Ändern</Button>
            </CardFooter>
            <h4 id="password-info" style={{ display: "none" }}></h4>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
