import React from 'react';
import { useState } from 'react';
import Button from "components/CustomButtons/Button.js";
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { makeStyles } from '@material-ui/core/styles';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import MaterialTable from 'material-table';
import Calendar from './Calendar';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import { List, ListItem, ListItemText, TextField } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

var axios = require("axios");
var data = [];

function isEquivalent(a, b) {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    if (aProps.length != bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    return true;
}


const useStyles = makeStyles(styles);
var allholidays = undefined;

export default class HolidayandSick extends React.Component {
    componentWillUnmount() {
        data = [];
        allholidays = undefined;
    }

    render() {
        return (
            <Holidays></Holidays>
        )
    }
}


function Holidays(props) {
    const [state, setState] = useState({
        selection0: {
            startDate: null,
            endDate: null,
            key: 'selection0'
        },
    });
    const [requested, setRequested] = React.useState(undefined);
    const [accepted, setAccepted] = React.useState(undefined);
    const [declined, setDeclined] = React.useState(undefined);
    const classes = useStyles();

    var today = new Date();
    var silvnextyear = new Date(today.getFullYear() + 1, 11, 31);
    var one_day = 1000 * 60 * 60 * 24;

    function addHolidays(params) {
        let url = 'https://' + global.localip + ':3005/getWorktimesbyId/' + window.loggedinid;
        fetch(url)
            .then(response => response.json())
            .then(responseJSON => {
                if (responseJSON.length === 0) {
                    alert("Bitte sprich einen Admin an. Deine Arbeitszeiten müssen noch gesetzt werden.")
                } else {
                    var url = "https://" + global.localip + ":3005/getNotDeclinedHolidaysbyId/" + window.loggedinid;
                    fetch(url)
                        .then(response => response.json())
                        .then(result2 => {
                            var useddaysbyyear = {};

                            function getDates(startDate, stopDate) {
                                var dateArray = new Array();
                                var currentDate = startDate;
                                while (currentDate <= stopDate) {
                                    dateArray.push(new Date(currentDate));
                                    currentDate = addDays(currentDate, 1);
                                }
                                return dateArray;
                            }
                            const startselect = new Date(state.selection0.startDate);
                            const endselect = new Date(state.selection0.endDate);
                            var alldatesselected = getDates(startselect, endselect);

                            for (let i = 0; i <= result2.length; i++) {
                                var element;
                                var select;
                                if (i === result2.length) {
                                    element = undefined;
                                    select = state.selection0;
                                } else {
                                    element = JSON.parse(result2[i].holidayobject);
                                    select = element.selection0;
                                }
                                const start = new Date(select.startDate);
                                const end = new Date(select.endDate);
                                var alldates = getDates(start, end);
                                if (element != undefined) {
                                    for (let i = 0; i < alldates.length; i++) {
                                        const element2 = alldates[i];
                                        for (let j = 0; j < alldatesselected.length; j++) {
                                            const element3 = alldatesselected[j];
                                            if (element2.getFullYear() === element3.getFullYear() && element2.getMonth() === element3.getMonth() && element2.getDate() === element3.getDate()) {
                                                alert("Der Tag " + element2.getDate() + ". " + element2.getMonth() + ". " + element2.getFullYear() + " wurde bereits von dir beantragt.");
                                                return;
                                            }
                                        }
                                    }
                                }


                                for (let i = 0; i < alldates.length; i++) {
                                    const element = alldates[i];

                                    if (global.WorkingDaysAt(element, window.loggedinid).includes(element.getDay())) {
                                        if (useddaysbyyear[element.getFullYear()] != undefined) {
                                            useddaysbyyear[element.getFullYear()] = useddaysbyyear[element.getFullYear()] + 1;
                                        } else {
                                            useddaysbyyear[element.getFullYear()] = 1;
                                        }
                                    }
                                }
                            }


                            var toomuch = false;
                            for (const key in useddaysbyyear) {
                                const element = useddaysbyyear[key];
                                var date = new Date();
                                date.setFullYear(key);
                                var amount = global.HolidayAAt(date, window.loggedinid);
                                console.log(element);
                                console.log(amount);

                                if (element > amount) {
                                    toomuch = true;
                                } else {
                                    toomuch = false;
                                }
                            }

                            if (toomuch) {
                                alert("Du hast mehr Urlaubstage angefragt, als dir zur Verfügung stehen. Bitte passe den Zeitraum an oder warte bis ein Admin deinen Antrag abgelehnt hat");
                                setState({
                                    selection0: {
                                        startDate: null,
                                        endDate: null,
                                        key: 'selection0'
                                    },
                                });
                            } else {
                                if (state.selection0.startDate != null && state.selection0.endDate != null) {
                                    var url2 = "https://" + global.localip + ":3005/saveHolidaysbyId/" + window.loggedinid;


                                    axios.post(url2, { holidayobject: state })
                                        .then(response => {
                                            window.hist.push("/admin/temp")
                                            window.hist.push("/admin/daysettings")
                                        })
                                        .catch(e => console.log(e))
                                    setState({
                                        selection0: {
                                            startDate: null,
                                            endDate: null,
                                            key: 'selection0'
                                        },
                                    })
                                }
                            }


                        })

                }
            })
    }

    if (window.loggedinadmin === 1) {
        var ranges = [];
        function checkCondition() {
            try {
                if (state.selection0.startDate === null && !allholidays) {
                    return true;
                }
            } catch (error) {
                return true;
            }
        }

        if (allholidays === undefined || checkCondition()) {
            var url = "https://" + global.localip + ":3005/getAllHolidays";
            fetch(url)
                .then(response => response.json())
                .then(result => {
                    data = [];
                    var newstate = {};
                    var j = 0;
                    var s = 0;
                    for (let i = 0; i < result.length; i++) {
                        const element = result[i];
                        var hobj = JSON.parse(element.holidayobject);

                        var selection = hobj.selection0;
                        selection.startDate = new Date(selection.startDate);
                        selection.endDate = new Date(selection.endDate);
                        selection.key = "selection" + s.toString();
                        if (element.status === 0) {
                            newstate["selection" + s.toString()] = selection;
                            s++;
                        }

                        var tableentry = {};

                        tableentry.id = element.id;
                        tableentry.status = element.status;
                        tableentry.userid = element.userid;
                        tableentry.startDate = selection.startDate;
                        tableentry.endDate = selection.endDate;
                        tableentry.reason = element.reason;

                        data.push(tableentry);
                        var url = "https://" + global.localip + ":3005/getUserbyId/" + element.userid;
                        fetch(url, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ sessionid: localStorage.getItem("sessionid") })
                        })
                            .then(response => response.json())
                            .then(result2 => {
                                try {
                                    j++;
                                    data[i].name = result2[0].name;
                                    data[i].surname = result2[0].surname;
                                    data[i].email = result2[0].email;

                                    if (data.length === j) {
                                        allholidays = true;

                                        if (!isEquivalent(state, newstate)) {
                                            setState(newstate);
                                        }
                                    }
                                } catch (error) {

                                }
                            })
                    }
                })
        }
        for (const key in state) {
            const element = state[key];
            ranges.push(element);
        }
        if (ranges.length === 0) {
            ranges.push({
                startDate: null,
                endDate: null,
                key: 'selection0'
            })
        }

        function getRequestedData() {
            var datarequested = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].status === 0) {
                    datarequested.push(data[i]);
                }
            }
            return datarequested;
        }
        function getAcceptedData() {
            var dataaccepted = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].status === 1) {
                    dataaccepted.push(data[i]);
                }
            }
            return dataaccepted;
        }
        function getDeclinedData() {
            var datadeclined = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].status === 2) {
                    datadeclined.push(data[i]);
                }
            }
            return datadeclined;
        }

        return (
            <div>
                <h3>Urlaubsanträge aller Nutzer</h3>
                <h5>Tabellarische & Graphische Ansicht</h5>
                <Grid container>
                    <GridItem xs={12} style={{ zIndex: "0" }}>
                        <Card>
                            <CardHeader color="warning">
                                <h4 className={classes.cardTitle} style={{ color: "white" }}>Offene Anträge</h4>
                            </CardHeader>
                            <CardBody>
                                <DateRange
                                    onChange={item => ""}
                                    ranges={ranges}
                                    weekStartsOn={1}
                                    months={3}
                                    direction="horizontal"
                                />
                                <MaterialTable
                                    columns={[
                                        { title: 'Vorname', field: 'name' },
                                        { title: 'Nachname', field: 'surname' },
                                        { title: 'Email', field: 'email' },
                                        { title: 'Anfang', field: 'startDate', type: "date" },
                                        { title: 'Ende', field: 'endDate', type: "date" },
                                        { title: 'Annehmen', field: 'accepted', render: rowData => <AcceptButton rowData={rowData} /> },
                                        { title: 'Ablehnen', field: 'denied', render: rowData => <DeniedButton rowData={rowData} /> },
                                    ]}
                                    data={(getRequestedData())}
                                    title="Offene Anträge:">
                                </MaterialTable>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={6} style={{ zIndex: "0" }}>
                        <Card>
                            <CardHeader color="success">
                                <h4 className={classes.cardTitle} style={{ color: "white" }}>Angenommene Anträge</h4>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable
                                    columns={[
                                        { title: 'Vorname', field: 'name' },
                                        { title: 'Nachname', field: 'surname' },
                                        { title: 'Email', field: 'email' },
                                        { title: 'Anfang', field: 'startDate', type: "date" },
                                        { title: 'Ende', field: 'endDate', type: "date" },
                                        { title: 'Grund', field: 'reason' },
                                        { title: 'Bearbeiten', field: 'denied', render: rowData => <DeniedButton rowData={rowData} /> },
                                    ]}
                                    data={(getAcceptedData())}
                                    title="Angenommene Anträge:">
                                </MaterialTable>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={6} style={{ zIndex: "0" }}>
                        <Card>
                            <CardHeader color="danger">
                                <h4 className={classes.cardTitle} style={{ color: "white" }}>Abgelehnte Anträge</h4>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable
                                    columns={[
                                        { title: 'Vorname', field: 'name' },
                                        { title: 'Nachname', field: 'surname' },
                                        { title: 'Email', field: 'email' },
                                        { title: 'Anfang', field: 'startDate', type: "date" },
                                        { title: 'Ende', field: 'endDate', type: "date" },
                                        { title: 'Grund', field: 'reason' },
                                        { title: 'Bearbeiten', field: 'accepted', render: rowData => <AcceptButton rowData={rowData} /> },
                                    ]}
                                    data={(getDeclinedData())}
                                    title="Abgelehnte Anträge:">
                                </MaterialTable>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} style={{ zIndex: "0" }}>
                        <Card>
                            <CardHeader color="info">
                                <h4 className={classes.cardTitle} style={{ color: "white" }}>Kalendarische Ansicht</h4>
                            </CardHeader>
                            <CardBody>
                                <Calendar datarequested={getRequestedData()} dataaccepted={getAcceptedData()}></Calendar>
                            </CardBody>
                        </Card>
                    </GridItem>
                </Grid>
            </div>
        )

        function AcceptButton(props) {
            const [open, setOpen] = React.useState(false);
            const [reason, setReason] = React.useState(" ");
            const handleClose = () => {
                setOpen(false);
            };
            const handleToggle = () => {
                setOpen(!open);
            };

            return (
                <div>
                    <Button color="success" onClick={handleToggle}>Annehmen</Button>
                    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                        <DialogTitle>Urlaub annehmen - Grund auswählen</DialogTitle>
                        <List>
                            <ListItem button onClick={() => {
                                acceptWithReason();
                            }}>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <ListItemText primary={"Keinen Grund angeben"} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button fullWidth color="primary" variant="contained">Setzen</Button>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <TextField
                                            label="Grund selber setzen"
                                            value={reason}
                                            fullWidth
                                            onChange={(e) => {
                                                var r = e.target.value;
                                                r = r.replace("/", ",");
                                                if (r.length <= 250) {
                                                    setReason(r);
                                                }
                                            }}
                                            multiline
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button fullWidth variant="contained" color="primary" onClick={() => {
                                            acceptWithReason();
                                        }}>Setzen</Button>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </List>
                    </Dialog>
                </div>
            )

            function acceptWithReason() {
                var url = "https://" + global.localip + ":3005/acceptHolidays/" + props.rowData.id + "/" + reason;
                fetch(url)
                    .then(response => console.log(response)
                    )
                    .then(result2 => {
                        global.hist.push("/admin/daysettings2")
                        allholidays = undefined;
                        global.hist.push("/admin/daysettings")
                    })
            }
        }

        function DeniedButton(props) {
            const [open, setOpen] = React.useState(false);
            const [reason, setReason] = React.useState("");
            const handleClose = () => {
                setOpen(false);
            };
            const handleToggle = () => {
                setOpen(!open);
            };

            return (
                <div>
                    <Button color="warning" onClick={handleToggle}>Ablehnen</Button>
                    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                        <DialogTitle>Urlaub ablehnen - Grund auswählen</DialogTitle>
                        <List>
                            <ListItem button onClick={() => {
                                denyWithReason();
                            }}>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <ListItemText primary={"Keinen Grund angeben"} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button fullWidth color="primary" variant="contained">Setzen</Button>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <ListItem>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <TextField
                                            label="Grund selber setzen"
                                            value={reason}
                                            fullWidth
                                            onChange={(e) => {
                                                var r = e.target.value;
                                                r = r.replace("/", ",");
                                                if (r.length <= 250) {
                                                    setReason(r);
                                                }
                                            }}
                                            multiline
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button fullWidth variant="contained" color="primary" onClick={() => {
                                            denyWithReason();
                                        }}>Setzen</Button>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </List>
                    </Dialog>
                </div>
            )

            function denyWithReason() {
                var url = "https://" + global.localip + ":3005/rejectHolidays/" + props.rowData.id + "/" + reason;
                fetch(url)
                    .then(response => response.json())
                    .then(result2 => {
                        global.hist.push("/admin/daysettings2")
                        allholidays = undefined;
                        global.hist.push("/admin/daysettings")
                    })
            }
        }
    } else {
        return (
            <>
                <h3>Urlaubsantrag stellen.</h3>
                <h5>Trage einen Urlaubswunsch ein und beantrage ihn durch einen Click auf "Urlaub beantragen". Ein Admin wird diesen zeitnah bearbeiten.</h5>
                <Grid container>
                    <GridItem xs={12} style={{ zIndex: "0" }}>
                        <Card>
                            <CardHeader color="info">
                                <h4 className={classes.cardTitle} style={{ color: "white" }}>Urlaubsantrag</h4>
                            </CardHeader>
                            <CardBody>
                                <DateRange
                                    onChange={item => setState({ ...state, ...item })}
                                    ranges={[state.selection0]}
                                    weekStartsOn={1}
                                    minDate={addDays(new Date(), 0)}
                                    maxDate={addDays(new Date(), Math.ceil((silvnextyear.getTime() - today.getTime()) / (one_day)))}
                                    months={3}
                                    direction="horizontal"
                                />
                                <Button onClick={addHolidays}>Urlaub beantragen</Button>
                                <RequestedHolidays requested={requested} setRequested={setRequested}></RequestedHolidays>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} style={{ zIndex: "0" }}>
                        <Card>
                            <CardHeader color="success">
                                <h4 className={classes.cardTitle} style={{ color: "white" }}>Angenommene Anträge</h4>
                            </CardHeader>
                            <CardBody>
                                <AcceptedHolidays accepted={accepted} setAccepted={setAccepted}></AcceptedHolidays>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} style={{ zIndex: "0" }}>
                        <Card>
                            <CardHeader color="danger">
                                <h4 className={classes.cardTitle} style={{ color: "white" }}>Abgelehnte Anträge</h4>
                            </CardHeader>
                            <CardBody>
                                <DeclinedHolidays declined={declined} setDeclined={setDeclined}></DeclinedHolidays>
                            </CardBody>
                        </Card>
                    </GridItem>
                </Grid>
            </>
        )
    }
}


function RequestedHolidays(props) {
    if (props.requested === undefined) {
        let url = 'https://' + global.localip + ':3005/getHolidayRequest/' + window.loggedinid;
        fetch(url)
            .then(response => response.json())
            .then(responseJSON => {
                if (responseJSON.length > 0) {
                    props.setRequested(responseJSON);
                }
            })

        return (
            <>
                <h3>Deine offenen Anträge</h3>
                Keine Anträge geladen...
            </>
        )
    } else {
        var data = [];
        for (let i = 0; i < props.requested.length; i++) {
            const element = props.requested[i];
            var parsedhobj = JSON.parse(element.holidayobject);
            data.push({ startDate: new Date(parsedhobj.selection0.startDate), endDate: new Date(parsedhobj.selection0.endDate) })
        }

        return (
            <>
                <h3>Deine offenen Anträge</h3>
                <MaterialTable
                    columns={[
                        { title: 'Anfang', field: 'startDate', type: "date" },
                        { title: 'End', field: 'endDate', type: "date" },
                    ]}
                    data={data}
                    title="Offene Anträge:">
                </MaterialTable>
            </>
        )
    }
}


function DeclinedHolidays(props) {
    if (props.declined === undefined) {
        let url = 'https://' + global.localip + ':3005/getDeclinedHolidaysbyId/' + window.loggedinid;
        fetch(url)
            .then(response => response.json())
            .then(responseJSON => {
                if (responseJSON.length > 0) {
                    props.setDeclined(responseJSON);
                }
            })

        return (
            <>
                <h3>Deine abgelehnten Anträge</h3>
                Keine Anträge geladen...
            </>
        )
    } else {
        var data = [];
        for (let i = 0; i < props.declined.length; i++) {
            const element = props.declined[i];
            var parsedhobj = JSON.parse(element.holidayobject);

            data.push({ startDate: new Date(parsedhobj.selection0.startDate), endDate: new Date(parsedhobj.selection0.endDate), reason: element.reason })
        }

        return (
            <>
                <h3>Deine abgelehnten Anträge</h3>
                <MaterialTable
                    columns={[
                        { title: 'Anfang', field: 'startDate', type: "date" },
                        { title: 'Ende', field: 'endDate', type: "date" },
                        { title: 'Grund', field: 'reason' }
                    ]}
                    data={data}
                    title="Abgelehnte Anträge:">

                </MaterialTable>
            </>
        )
    }
}
function AcceptedHolidays(props) {
    const [state, setState] = useState({
        selection0: {
            startDate: null,
            endDate: null,
            key: 'selection0'
        },
    });
    if (props.accepted === undefined) {
        let url = 'https://' + global.localip + ':3005/getHolidaysbyId/' + window.loggedinid;
        fetch(url)
            .then(response => response.json())
            .then(responseJSON => {
                if (responseJSON.length > 0) {
                    props.setAccepted(responseJSON);
                    var newstate = {};
                    for (let i = 0; i < responseJSON.length; i++) {
                        const element = responseJSON[i];
                        var hobj = JSON.parse(element.holidayobject);

                        var selection = hobj.selection0;
                        selection.startDate = new Date(selection.startDate);
                        selection.endDate = new Date(selection.endDate);
                        selection.key = "selection" + i.toString();

                        newstate["selection" + i.toString()] = selection;

                        if (responseJSON.length === i + 1) {
                            setState(newstate);
                        }

                    }
                }
            })

        return (
            <>
                <h3>Deine angenommenen Anträge</h3>
                Keine Anträge geladen...
            </>
        )
    } else {
        var data = [];
        for (let i = 0; i < props.accepted.length; i++) {
            const element = props.accepted[i];
            var parsedhobj = JSON.parse(element.holidayobject);
            data.push({ startDate: new Date(parsedhobj.selection0.startDate), endDate: new Date(parsedhobj.selection0.endDate), reason: element.reason })
        }
        var ranges = [];
        for (const key in state) {
            const element = state[key];
            ranges.push(element);
        }

        return (
            <>
                <h3>Deine angenommenen Anträge</h3>
                <MaterialTable
                    columns={[
                        { title: 'Anfang', field: 'startDate', type: "date" },
                        { title: 'Ende', field: 'endDate', type: "date" },
                        { title: 'Grund', field: 'reason' }
                    ]}
                    data={data}
                    title="Angenommene Anträge:">
                </MaterialTable>
                <DateRange
                    onChange={item => ""}
                    ranges={ranges}
                    weekStartsOn={1}
                    months={3}
                    direction="horizontal"
                />
            </>
        )
    }
}
