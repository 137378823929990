import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, Button, Snackbar, Checkbox } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';


export default function AddWork() {
    const [startDate, setStartDate] = React.useState(new Date(new Date().setSeconds(0)));
    const [endDate, setEndDate] = React.useState(new Date(new Date().setSeconds(0)));
    const [state, setState] = React.useState({ open: false, msg: "Test", severity: "" });
    const [workorbrake, setWorkorBrake] = React.useState(true);//true = work, false = brake

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function handleStartChange(params) {
        setStartDate(new Date(params));
    }

    function handleEndChange(params) {
        setEndDate(new Date(params));
    }

    function handleWorkorBrake(params) {
        setWorkorBrake(!workorbrake);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setState({ open: false, msg: "", severity: "" });
    };

    return (
        <div>
            <Snackbar open={state.open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={state.severity} >
                    {state.msg}
                </Alert>
            </Snackbar>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container>
                    <Grid item xs={12}>
                        <h3>
                            Wähle Anfang und / oder Ende aus. Achte auf die korrekte Auswahl von Vor- und Nachmittag.
                            <br></br>
                            Achte darauf, ob du Arbeit oder eine Pause nachtragen möchtest.
                        </h3>
                    </Grid>
                    <Grid item xs={12}>
                        <h4>1. Wähle, ob du Arbeitszeit oder eine Pause nachtragen möchtest.</h4>
                    </Grid>
                    <Grid item xs={2}>
                        Arbeitszeit nachtragen:
                        <Checkbox checked={workorbrake} onChange={handleWorkorBrake}></Checkbox>
                    </Grid>
                    <Grid item xs={2}>
                        Pause nachtragen:
                        <Checkbox checked={!workorbrake} onChange={handleWorkorBrake}></Checkbox>
                    </Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={12}>
                        <h4>2. Wähle Anfangs- und Endzeit aus.</h4>
                    </Grid>
                    <Grid item xs={2}>Anfangszeit: </Grid>
                    <Grid item xs={2}>
                        <KeyboardDateTimePicker
                            ampm={false}
                            value={startDate}
                            onChange={handleStartChange} />
                    </Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={2}>Endzeit: </Grid>
                    <Grid item xs={2}>
                        <KeyboardDateTimePicker
                            ampm={false}
                            value={endDate}
                            onChange={handleEndChange} />
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={12}>
                        <h4>3. Warte auf Bestätigung durch einen Administrator.</h4>
                    </Grid>
                    <Button variant="contained" color="primary"
                        onClick={() => {
                            if (new Date(startDate).getTime() >= new Date().getTime() || new Date(endDate).getTime() >= new Date().getTime()) {
                                setState({ open: true, msg: "Du kannst keine Daten wählen, die in der Zukunft liegen", severity: "error" });
                                return;
                            }
                            if (new Date(startDate).getTime() < new Date(endDate).getTime()) {
                                if (workorbrake) {
                                    var url4 = "https://" + global.localip + ":3005/requestinsertWork/" + window.loggedinid + "/" + startDate + "/" + endDate + "/1";
                                    fetch(url4)
                                        .then(response => response.json())
                                        .then(result4 => {
                                            if (result4.reason !== undefined) {
                                                if (result4.reason === "dates between") {
                                                    setState({ open: true, msg: "Es befinden sich Daten zwischen deinen gewählten Zeiten. Bitte passe den Zeitraum an.", severity: "error" });
                                                } else if (result4.reason === "stampedin before") {
                                                    setState({ open: true, msg: "Du warst an dem ausgewähltem Anfang bereits eingestempelt", severity: "error" });
                                                } else if(result4.reason === "only one"){
                                                    setState({ open: true, msg: "Du hast noch einen Antrag offen, warte bis dieser bearbeitet wurde.", severity: "error" });
                                                }
                                            } else {
                                                setState({ open: true, msg: "Arbeitsantrag hinzugefügt", severity: "success" });
                                                setStartDate(new Date());
                                                setEndDate(new Date());
                                            }
                                        })
                                } else {
                                    var url5 = "https://" + global.localip + ":3005/insertWorkorBrake/" + window.loggedinid + "/" + startDate + "/" + endDate + "/0";
                                    fetch(url5)
                                        .then(response => response.json())
                                        .then(result4 => {
                                            if (result4.reason !== undefined) {
                                                if (result4.reason === "dates between") {
                                                    setState({ open: true, msg: "Es befinden sich Daten zwischen deinen gewählten Zeiten. Bitte passe den Zeitraum an.", severity: "error" });
                                                } else if (result4.reason === "stampedout before") {
                                                    setState({ open: true, msg: "Du warst an dem ausgewähltem Anfang bereits in der Pause", severity: "error" });
                                                }
                                            } else {
                                                setState({ open: true, msg: "Pause hinzugefügt", severity: "success" });
                                                setStartDate(new Date());
                                                setEndDate(new Date());
                                            }
                                        })
                                }
                            } else {
                                setState({ open: true, msg: "Der Anfang muss vor dem Ende liegen", severity: "error" });
                            }
                        }}>
                        {workorbrake && "Arbeitszeit nachtragen"}
                        {!workorbrake && "Pause nachtragen"}
                    </Button>
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    )
}
