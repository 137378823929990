import React, { useState, Component } from 'react'
import MaterialTable from 'material-table';
import {
    Calendar,
    DateLocalizer,
    momentLocalizer,
    globalizeLocalizer,
    move,
    Views,
    Navigate,
    components,
} from 'react-big-calendar'
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
var axios = require("axios");

moment.locale('ko', {
    week: {
        dow: 1,
        doy: 1,
    },
});

const addDays = function (sdate, days) {
    var date = new Date(sdate);
    date.setDate(date.getDate() + days);
    return date;
}
const localizer = momentLocalizer(moment);
var calculating = false;

//MissedDays Wrapper
export default class Missed extends Component {
    componentWillUnmount() {
        calculating = false;
    }

    render() {
        return (
            <MissedDays></MissedDays>
        )
    }
}

//missedDays
function MissedDays() {
    const [years, setYears] = useState([]);
    const [holidays, setHolidays] = useState(undefined);
    const [data, setData] = useState([]);
    //Wenn keine daten
    if (data == "datac") {
        return (
            <div>
                Keine Daten zum Anzeigen vorhanden.
            </div>
        )
    }


    if (data.length == 0 && !calculating) {
        calculating = true;
        //User laden
        let url2 = 'https://' + global.localip + ':3005/getUsers';
        fetch(url2, { 
          method: 'POST', 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ sessionid: localStorage.getItem("sessionid") }) 
        })
            .then(response => response.json())
            .then(resu => {
                for (let i = 0; i < resu.length; i++) {
                    //Für jeden Users ->
                    const element = resu[i];
                    //worktimes laden
                    let url = 'https://' + global.localip + ':3005/getWorktimesbyId/' + element.id;
                    fetch(url)
                        .then(response => response.json())
                        .then(result => {
                            if (result.length > 0) {
                                const element2 = element;
                                //User getnotdeclinedholidays
                                var url = "https://" + global.localip + ":3005/getNotDeclinedHolidaysbyId/" + element.id;
                                fetch(url)
                                    .then(response => response.json())
                                    .then(result2 => {
                                        //Array für Urlaubs und Arbeitstage
                                        var collecteddates = [];
                                        //Funktion um zu gucken ob Urlaub oder gearbeitet
                                        function isHolidayOrDidWork(date) {
                                            for (let i = 0; i < collecteddates.length; i++) {
                                                const element = collecteddates[i];
                                                if (element.getFullYear() == date.getFullYear() && element.getMonth() == date.getMonth() && element.getDate() == date.getDate()) {
                                                    return true;
                                                }
                                            }
                                            return false;
                                        }
                                        //getDates für Tage zwischen zwei Daten
                                        function getDates(startDate, stopDate) {
                                            var dateArray = new Array();
                                            var currentDate = startDate;
                                            while (currentDate <= stopDate) {
                                                dateArray.push(new Date(currentDate));
                                                currentDate = addDays(currentDate, 1);
                                            }
                                            return dateArray;
                                        }
                                        for (let i = 0; i < result2.length; i++) {
                                            //Für jedes Item in result2 Holiday Array
                                            var element;
                                            var select;
                                            element = JSON.parse(result2[i].holidayobject);
                                            select = element.selection0;

                                            const start = new Date(select.startDate);
                                            const end = new Date(select.endDate);
                                            var alldates = getDates(start, end);
                                            for (let i = 0; i < alldates.length; i++) {
                                                const element = alldates[i];
                                                //Wenn User an dem Urlaubstag eigentlich arbeiten sollte
                                                if (global.WorkingDaysAt(element, element2.id).includes(element.getDay())) {
                                                    //push in dates
                                                    collecteddates.push(element);
                                                }
                                            }
                                        }
                                        //Stamps für User laden
                                        let url = 'https://' + global.localip + ':3005/getStamp/' + resu[i].id;
                                        fetch(url)
                                            .then(response => response.json())
                                            .then(resultstamp => {
                                                if (resultstamp.length > 0) {
                                                    //get first date
                                                    var start = resultstamp[resultstamp.length - 1].stamp;
                                                    start = start.replace("T", " ");
                                                    start = start.replace("Z", " ");
                                                    let dateTimParts = start.split(/[- :]/);
                                                    dateTimParts[1]--;
                                                    start = new Date(...dateTimParts);
                                                    //für jeden Stamp
                                                    for (let i = 0; i < resultstamp.length; i++) {
                                                        const stamp = resultstamp[i];
                                                        stamp.stamp = stamp.stamp.replace("T", " ");
                                                        stamp.stamp = stamp.stamp.replace("Z", " ");
                                                        let dateTimeParts = stamp.stamp.split(/[- :]/);

                                                        dateTimeParts[1]--;
                                                        const dateObject = new Date(...dateTimeParts);
                                                        dateObject.setHours(dateObject.getHours() + 2);
                                                        //gucken das Tag noch nicht drinne ist
                                                        if (!isHolidayOrDidWork(dateObject)) {
                                                            collecteddates.push(dateObject)
                                                        }
                                                    }
                                                    //Alle tage zwischen Anfang und jetzt
                                                    var alldaysbetween = getDates(start, new Date());

                                                    var datac = data;
                                                    //Missing reasons fetchen
                                                    var url3 = "https://" + global.localip + ":3005/getMissingReasons/" + resu[i].id;
                                                    axios.get(url3)
                                                        .then(response => {
                                                            //get Reason eines Tages
                                                            function getReason(date) {
                                                                for (let i = 0; i < response.data.length; i++) {
                                                                    const element = response.data[i];
                                                                    const day = parseInt(response.data[i].day.split(".")[0]);
                                                                    const month = parseInt(response.data[i].day.split(".")[1]);
                                                                    const year = parseInt(response.data[i].day.split(".")[2]);
                                                                    if (date.getDate() == day && (date.getMonth() + 1) == month && date.getFullYear() == year) {
                                                                        return element.reason;
                                                                    }
                                                                }
                                                                return 0;
                                                            }

                                                            //Für alle Tage seit arbeitsbeginn
                                                            for (let j = 0; j < alldaysbetween.length; j++) {
                                                                const element = alldaysbetween[j];
                                                                //Wenn nicht Urlaub oder gearbeitet hat
                                                                if (!isHolidayOrDidWork(element)) {
                                                                    if (global.WorkingDaysAt(element, resu[i].id).includes(element.getDay())) {
                                                                        var reason = getReason(element);
                                                                        var reasonstr = "";
                                                                        if (reason == 0) {
                                                                            reasonstr = "Kein Fehlgrund gesetzt"
                                                                        } else if (reason == 2) {
                                                                            reasonstr = "Sonderurlaub"
                                                                        } else if (reason == 3) {
                                                                            reasonstr = "Krankentag"
                                                                        }
                                                                        var entry = { title: resu[i].name + " " + resu[i].surname + " / " + reasonstr, start: element, end: element, allDay: true, resource: "test", userid: resu[i].id, reason: reason };
                                                                        datac.push(entry);
                                                                    }
                                                                }
                                                                //set Data at last entry
                                                                if (j == (alldaysbetween.length - 1)) {
                                                                    setData("datac");
                                                                    setData(datac);
                                                                }
                                                            }
                                                        })
                                                        .catch(e => console.log(e))

                                                }
                                            })

                                    })
                            }
                        })
                }
            })
    }

    //Laden von Feiertagen
    let yearsc = [...years]; 
    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        let year = element.start.getFullYear();
        if(!yearsc.includes(year)){
            yearsc.push(year);
        }
    }
    if(years.length != yearsc.length){
        yearsc.sort(function(a, b){return a-b});
        setYears(yearsc);
        try {
            var url4 = "https://feiertage-api.de/api/?jahr=";
            var url4_2 = "&nur_land=HH";
            var startyear = yearsc[0];
            var endyear = yearsc[yearsc.length-1];
            let did = 0;
            let holidaysa = [];
            for (let i = startyear; i <= endyear; i++) {
                fetch(url4 + i + url4_2)
                    .then(response => response.json())
                    .then(result4 => {
                        holidaysa[i] = result4;
                        did++;

                        if (did == (endyear - startyear + 1)) {
                            setHolidays(holidaysa);
                        }
                    })
            }
        } catch (error) {

        }
    }

    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (data[i + 1] != undefined) {
            const element2 = data[i + 1];
            if (element2.title == element.title) {
                if (element2.end.getFullYear() == element.end.getFullYear() && element2.end.getMonth() == element.end.getMonth() && element2.end.getDate() == element.end.getDate() + 1) {
                    data[i].end = element2.end;
                    data.splice(i + 1, 1);
                    i--;
                }
            }
        }
    }
    console.log(data);
    console.log(years);
    if(holidays != undefined){
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.start.getFullYear() == element.end.getFullYear() && element.start.getMonth() == element.end.getMonth() && element.start.getDate() == element.end.getDate()) {
                if(isLawHoliday(element.start)){
                    data.splice(i, 1);
                    i--;
                }
            } else {
                if(isLawHoliday(element.end)){
                    element.end.setDate(element.end.getDate()-1);
                    i--;
                } else if(isLawHoliday(element.start)){
                    element.start.setDate(element.start.getDate()+1);
                    i--;
                }
            }
        }
    }

    function isLawHoliday(date) {
        var hthisyear = holidays[date.getFullYear()];
        for (const key in hthisyear) {
            const element = hthisyear[key];
            if (element.datum == date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)) {
                return true;
            }
        }
        return false;
    }


    if (data.length == 0) {
        return (
            <div>
                Keine Daten zum Anzeigen vorhanden.
            </div>
        )
    }

    return (
        <div style={{ maxWidth: "100%" }}>
            <h3>Verpasste Arbeitstage</h3>
            <p>Hier siehst du von allen Benutzern Verpasste Arbeitstage. Du kannst diese zu Urlaubstagen / Sonderurlaub umwandeln oder als Krankentag stehen lassen.</p>
            <Calendar
                localizer={localizer}
                events={data}
                startAccessor="start"
                endAccessor="end"
                style={{ height: "1000px" }}
                views={['month']}
                tooltipAccessor='tooltip'
                components={{
                    event: EventComponent
                }}
            />
        </div>
    )
}

function getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate = addDays(currentDate, 1);
    }
    return dateArray;
}

function EventComponent(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [reason, setReason] = React.useState(props.event.reason);

    const handleChange = (event) => {
        setReason(event.target.value);
        
        if (event.target.value == 1) {
            //Insert as Holiday
            
            var url2 = "https://" + global.localip + ":3005/saveHolidaysbyIdAndAccept/" + props.event.userid;
            var obj = { selection0: { startDate: props.event.start, endDate: props.event.end, key: "selection0" } }
            axios.post(url2, { holidayobject: obj })
                .then(response => {
                    window.hist.push("/admin/temp")
                    window.hist.push("/admin/missed")
                })
                .catch(e => console.log(e))
        } else {
            var alldates = getDates(props.event.start, props.event.end);
            var done = 0;
            
            for (let i = 0; i < alldates.length; i++) {
                const element = alldates[i];
                var date = element.getDate() + "." + (element.getMonth() + 1) + "." + element.getFullYear();
                var url2 = "https://" + global.localip + ":3005/setMissingReason/" + props.event.userid + "/" + event.target.value;
                axios.post(url2, { date: date })
                    .then(response => {
                        done++;
                        if (done == alldates.length) {
                            window.hist.push("/admin/temp")
                            window.hist.push("/admin/missed")
                        }
                    })
                    .catch(e => console.log(e))
            }
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return <div style={{ width: "300px" }}>
        <div onClick={handleClick}>
            {props.event.title}
        </div>
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div style={{padding: "10px"}}>
            Bitte wähle einen Grund des Arbeitsausfalls aus.
            <br></br>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={reason}
                onChange={handleChange}
            >
                <MenuItem disabled value={0}>Kein Fehlgrund gesetzt</MenuItem>
                <MenuItem value={1}>Urlaub</MenuItem>
                <MenuItem value={2}>Sonderurlaub</MenuItem>
                <MenuItem value={3}>Krank</MenuItem>
            </Select>
            <CloseIcon onClick={handleClose}></CloseIcon>
            </div>
        </Popover>
    </div>
}
