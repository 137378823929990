import React, { useState } from "react";
import { TimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


var fetched = false;
var uuid4 = require("uuid4")
var axios = require("axios")
var id = undefined;
var own = false;

export default class WorkTimes extends React.Component {

    constructor(props) {
        super(props);
        if (props.own) {
            own = true;
        } else {
            own = false;
        }
    }

    componentWillMount() {
        fetched = false;
    }

    render() {
        var monthlystamps = this.props.monthlystamps;
        var admin = window.loggedinadmin;

        if (!fetched || id != this.props.id) {
            //global.currentWorktimeObject(this.props.id);
            let url = 'https://' + global.localip + ':3005/getWorktimesbyId/' + this.props.id;
            global.worktimesdata = undefined;
            id = this.props.id;
            fetch(url)
                .then(response => response.json())
                .then(responseJSON => {
                    global.worktimesdata = responseJSON;
                    if (global.worktimesdata.length == 0) {
                        global.worktimesdata = [];
                    } else {
                        global.worktimesdata = JSON.parse(global.worktimesdata[0].worktimeobject);
                    }
                    fetched = true;
                    this.forceUpdate();
                })
        }

        if (admin == 1) {
            admin = true;
        } else {
            admin = false;
        }

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <br></br>
                <TableDates worktimes={global.currentWorktimeObject(this.props.id)}></TableDates>
            </MuiPickersUtilsProvider>

        )
    }
}

var days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

function TableDates(props) {
    if (props.worktimes != undefined) {
        if (props.worktimes.length >= 1) {
            //Vorhanden
            return (
                <div>
                    <GetDays new={false} worktimes={props.worktimes}></GetDays>
                </div>
            )
        } else {
            //Erstellen
            return (
                <div>
                    <GetDays new={true}></GetDays>
                </div>
            )
        }
    } else {
        return (
            <div>
                Loading...
            </div>
        )
    }
}

function GetDays(props) {
    var start = new Date();
    var end = new Date();
    start.setHours(9)
    start.setMinutes(0);
    start.setSeconds(0);
    end.setHours(17)
    end.setMinutes(0);
    end.setSeconds(0);
    var starts = [];
    var ends = [];
    var disableds = [];
    var weeklyh = 0;
    var holidayss = 0;

    for (let i = 0; i < 7; i++) {
        if (props.worktimes == undefined) {
            starts[i] = start;
            ends[i] = end;
            disableds[i] = true;
        } else {
            var foundid = undefined;
            for (let index = 0; index < props.worktimes.length; index++) {
                const element = props.worktimes[index];
                if (element.day != undefined) {
                    if (element.day == i) {
                        foundid = index;
                    }
                } else {
                    if (element.week != undefined) {
                        weeklyh = element.week;
                    } else if (element.holidays != undefined) {
                        holidayss = element.holidays;
                    }
                }
            }
            if (foundid == undefined) {
                starts[i] = start;
                ends[i] = end;
                disableds[i] = false;
            } else {
                var start2 = new Date();
                start2.setHours(props.worktimes[foundid].start.split(":")[0]);
                start2.setMinutes(props.worktimes[foundid].start.split(":")[1]);
                start2.setSeconds(0);
                var end2 = new Date();
                end2.setHours(props.worktimes[foundid].end.split(":")[0]);
                end2.setMinutes(props.worktimes[foundid].end.split(":")[1]);
                end2.setSeconds(0);
                starts[i] = start2;
                ends[i] = end2;
                disableds[i] = true;
            }
        }
    }

    const [selectedDate00, handleDateChange00] = useState(starts[0]);
    const [selectedDate10, handleDateChange10] = useState(starts[1]);
    const [selectedDate20, handleDateChange20] = useState(starts[2]);
    const [selectedDate30, handleDateChange30] = useState(starts[3]);
    const [selectedDate40, handleDateChange40] = useState(starts[4]);
    const [selectedDate50, handleDateChange50] = useState(starts[5]);
    const [selectedDate60, handleDateChange60] = useState(starts[6]);
    const [selectedDate01, handleDateChange01] = useState(ends[0]);
    const [selectedDate11, handleDateChange11] = useState(ends[1]);
    const [selectedDate21, handleDateChange21] = useState(ends[2]);
    const [selectedDate31, handleDateChange31] = useState(ends[3]);
    const [selectedDate41, handleDateChange41] = useState(ends[4]);
    const [selectedDate51, handleDateChange51] = useState(ends[5]);
    const [selectedDate61, handleDateChange61] = useState(ends[6]);
    const [holidays, setHoliday] = React.useState(holidayss);
    const [workingh, setWorkingH] = React.useState(weeklyh);

    const [disabled, setDisabled] = useState({
        0: disableds[0],
        1: disableds[1],
        2: disableds[2],
        3: disableds[3],
        4: disableds[4],
        5: disableds[5],
        6: disableds[6]
    });

    const [focus, setFocus] = useState(0);
    var focusfirst = false;
    var focussecond = false;
    if (focus == 1) {
        focussecond = true;
    } else {
        focusfirst = true;
    }

    const handleHolidays = (event) => {
        setHoliday(event.target.value);
    };

    const handleWorkingH = (event) => {
        setWorkingH(event.target.value);
    };

    const handleChange = event => {
        setDisabled({ ...disabled, [event.target.name]: event.target.checked });
    };

    const [state, setState] = React.useState({ open: false, msg: "Test", severity: "" });
    function saveWorktimes() {
        if (!own) {
            var worktimes = [];
            for (let key in disabled) {
                if (disabled[key] == true) {
                    var startd = new Date(eval("selectedDate" + key + "0"))
                    var endd = new Date(eval("selectedDate" + key + "1"))
                    var start = startd.getHours() + ":" + startd.getMinutes();
                    var end = endd.getHours() + ":" + endd.getMinutes();
                    worktimes.push({ day: key, start: start, end: end })
                }
            }

            worktimes.push({ week: workingh })
            worktimes.push({ holidays: holidays })

            var url = "https://" + global.localip + ":3005/saveWorktimesbyId/" + id;
            axios.post(url, worktimes)
                .then(response => {
                    global.fetchWorktimesbyUserid(id);
                    let url = 'https://' + global.localip + ':3005/getWorktimesbyId/' + id;
                    fetch(url)
                        .then(response => response.json())
                        .then(responseJSON => {
                            global.worktimesdata = responseJSON;
                            global.worktimesdata = JSON.parse(global.worktimesdata[0].worktimeobject);
                            setState({ open: true, msg: "Arbeitszeiten erfolgreich geändert", severity: "success" });
                        })
                })
                .catch(e => console.log(e))
        }

    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClick = () => {
        var statec = state;
        statec.open = true;
        setState(statec);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState({ open: false, msg: "", severity: "" });
    };

    return (
        days.map(function (day, i) {
            if (i == 6) {
                return (
                    <div key={uuid4()}>
                        <Snackbar open={state.open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity={state.severity} >
                                {state.msg}
                            </Alert>
                        </Snackbar>
                        <Grid container spacing={3}>
                            <Grid item xs={2}>
                                {day}
                            </Grid>
                            <Grid item xs={4}>
                                <TimePicker
                                    ampm={false}
                                    label="Anfang"
                                    value={eval("selectedDate" + i + "0")}
                                    onChange={eval("handleDateChange" + i + "0")}
                                    disabled={!disabled[i]}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TimePicker
                                    ampm={false}
                                    label="Ende"
                                    value={eval("selectedDate" + i + "1")}
                                    onChange={eval("handleDateChange" + i + "1")}
                                    disabled={!disabled[i]}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Switch
                                    checked={disabled[i]}
                                    onChange={handleChange}
                                    name={i.toString()}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    disabled={own}
                                />
                            </Grid>
                        </Grid>
                        <br></br>
                        <Grid container spacing={3}>
                            <Grid item xs={2}>
                                Wöchentliche Arbeitsstunden
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    autoFocus={focusfirst}
                                    id="outlined-number"
                                    label="Number"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={workingh}
                                    onChange={handleWorkingH}
                                    onClick={() => {
                                        setFocus(0);
                                    }}
                                    disabled={own}
                                />
                            </Grid>
                        </Grid>
                        <br></br>
                        <Grid container spacing={3}>
                            <Grid item xs={2}>
                                Jährliche Urlaubstage
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    autoFocus={focussecond}
                                    id="outlined-number2"
                                    label="Number"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={holidays}
                                    onChange={handleHolidays}
                                    onClick={() => {
                                        setFocus(1);
                                    }}
                                    disabled={own}
                                />
                            </Grid>
                        </Grid>
                        <Button color="danger" onClick={saveWorktimes}>Speichern</Button>
                    </div>
                )
            } else {
                return (
                    <div key={uuid4()}>
                        <Grid container spacing={3}>
                            <Grid item xs={2}>
                                {day}
                            </Grid>
                            <Grid item xs={4}>
                                <TimePicker
                                    ampm={false}
                                    label="Anfang"
                                    value={eval("selectedDate" + i + "0")}
                                    onChange={eval("handleDateChange" + i + "0")}
                                    disabled={!disabled[i]}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TimePicker
                                    ampm={false}
                                    label="Ende"
                                    value={eval("selectedDate" + i + "1")}
                                    onChange={eval("handleDateChange" + i + "1")}
                                    disabled={!disabled[i]}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Switch
                                    checked={disabled[i]}
                                    onChange={handleChange}
                                    name={i.toString()}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    disabled={own}
                                />
                            </Grid>
                        </Grid>
                        <br></br>
                    </div>
                )
            }

        })
    )
}