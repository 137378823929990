/*eslint-disable*/
import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";
import InfoDropdown from "components/InfoDropdown/InfoDropdown.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

import TextField from '@material-ui/core/TextField';

import Switch2 from '@material-ui/core/Switch';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Box } from "@material-ui/core";
import MailIcon from '@material-ui/icons/Mail';
import InputAdornment from '@material-ui/core/InputAdornment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GetAvatar from "views/UserProfile/GetAvatar";
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { Grid, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, routes } = props;
  var links = (
    <List className={classes.list}>
      <Stampinandout></Stampinandout>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path)
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
        });
        var style = {};
        if (prop.path === "/login") {
          style = { color: "red" }
        }
        return (
          <NavLink
            onClick={() => {
              if (prop.path === "/login") {
                localStorage.setItem('sessionid', null);
              }
            }}
            to={prop.layout + prop.path}
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                >
                  {prop.icon}
                </Icon>
              ) : (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive
                    })}
                    style={style}
                  />
                )}
              <ListItemText
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: props.rtlActive
                })}
                disableTypography={true}
                style={style}
              >
                {prop.name}
              </ListItemText>
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        onClick={(e) => {
          e.preventDefault();
          global.hist.push("/admin/dashboard")
        }}
        href=""
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
        target="_blank"
      >
        <div className={classes.logoImage} style={{ textAlign: "center", minHeight: "200px" }}>
          <img src={logo} alt="logo" className={classes.img} style={{ width: "170px" }} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};


global.email = "";
var refetchstamp = false;
var updater;
class Stampinandout extends React.Component {
  render() {
    updater = this;
    //console.log(updater);
    if (global.refetchstampintervall == undefined) {
      global.refetchstampintervall = setInterval(() => {
        setTimeout(() => {
          setTimeout(() => {
            setTimeout(() => {
              setTimeout(() => {
                refetchstamp = true;
                this.forceUpdate();
              }, 1000);
              this.forceUpdate();
            }, 1000);
            this.forceUpdate();
          }, 1000);
          this.forceUpdate();
        }, 1000);
      }, 5000);
    }
    var text = "";
    function forceUpdt() {
      //console.log(updater);     
      try {
        refetchstamp = true;
        document.getElementById("stamptime").nodeValue = "";
        updater.forceUpdate();
      } catch (error) {
        //console.log(error);
      }
    }

    if (global.inoroutbool) {
      text = "Eingestempelt"
    } else {
      text = "Ausgestempelt"
    }
    var color;

    if (global.inoroutbool) {
      color = "green";
    } else {
      color = "red";
    }

    var switchchecked;
    if (global.inoroutbool) {
      switchchecked = true;
    } else {
      switchchecked = false;
    }

    return (
      <div>
        <Box display="flex" flexDirection="row" style={{ position: "fixed", top: "0px", right: "0px", padding: "15px", zIndex: "999999" }} >
          <InfoDropdown></InfoDropdown>
          <GetAvatar id={window.loggedinid} color={color} onClick={() => {
            global.hist.push("/admin/user");
          }}></GetAvatar>
        </Box>
        <StampManagment text={text} switchchecked={switchchecked} forceUpdt={forceUpdt}></StampManagment>
      </div>
    )
  }
}

function StampManagment(props) {
  const [state, setState] = React.useState({ open: false, msg: "", severity: "success" });
  const classes = useStyles();

  if (window.loggedinadmin == 1) {
    return (
      <></>
    )
  }


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({ open: false, msg: "", severity: "success" });
  };


  if (refetchstamp) {
    refetchstamp = false;
    let url2 = 'https://' + global.localip + ':3005/getLastStamp/' + window.loggedinid;
    fetch(url2)
      .then(response => response.json())
      .then(result => {
        try {
          window.laststamp = result[0];

          if (window.laststamp.inorout == 1) {
            global.inoroutbool = true;
          } else if (window.laststamp.inorout == 0) {
            global.inoroutbool = false;
          }
        } catch (error) {

        }
      })
  }

  return (
    <div id="stampinorout" style={{ textAlign: "center" }} className={classes.logo}>
      <h3 style={{ color: "white" }}>{props.text}</h3>
      <IOSSwitch id="stampswitch" checked={props.switchchecked} onChange={() => {
        refetchstamp = true;
        if (global.inoroutbool) {
          setState({ open: true, msg: "Du bist nun ausgestempelt.", severity: "error" });
        } else {
          setState({ open: true, msg: "Du bist nun eingestempelt.", severity: "success" });
        }
        
        changeStamp(props.forceUpdt);
        
      }}></IOSSwitch>
      <Timer></Timer>
      <Snackbar open={state.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={state.severity} >
          {state.msg}
        </Alert>
      </Snackbar>
    </div>
  )
}



var calculatestamp = false;

function Timer() {
  const [time, setTime] = useState(0);

  function getTime() {
    if (calculatestamp == true) {
      var d = new Date(window.laststamp.stamp);
      var now = new Date();
      var diff = d.getTime() - now.getTime();
      var totalSeconds = Math.abs(diff / 1000);
      var hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      var minutes = Math.floor(totalSeconds / 60);
      var seconds = Math.floor(totalSeconds % 60);
      hours = ("0" + hours).slice(-2);
      minutes = ("0" + minutes).slice(-2);
      seconds = ("0" + seconds).slice(-2);
      return hours + ":" + minutes + ":" + seconds;
    }
    return time + 1;
  }

  setTimeout(() => {
    setTime(getTime());
  }, 1000);

  if (window.laststamp != undefined) {
    if (global.inoroutbool == 0) {
      return (
        <div style={{ marginBottom: "30px" }}></div>
      )
    } else {
      calculatestamp = true;
      return (
        <div style={{ color: "white", marginBottom: "30px" }}>Bei der Arbeit seit: <div id="stamptime" style={{ display: "inline-block" }}>{time}</div></div>
      )
    }
  }

  return (
    <div>

    </div>
  )
}


function changeStamp(callback) {
  if (global.laststampinorout != undefined) {

    if (((global.laststampinorout.getTime() / 1000) - (new Date().getTime() / 1000)) > -5) {
      alert("Bitte warte mindestens 5 Sekunden zwischen Stempelvorgängen");
      return;
    }
  }
  global.laststampinorout = new Date();
  if (window.loggedinid != undefined) {
    let url = 'https://' + global.localip + ':3005/stampinorout/' + window.loggedinid;
    fetch(url).then(response => response.json())
      .then(result => {
        let url2 = 'https://' + global.localip + ':3005/getLastStamp/' + window.loggedinid;
        fetch(url2)
          .then(response => response.json())
          .then(result => {
            window.laststamp = result[0];
            if (global.inoroutbool == false) {
              global.inoroutbool = true;
            } else if (global.inoroutbool == true) {
              global.inoroutbool = false;
            }

            setTimeout(() => {
              callback();
            }, 100);
            //global.hist.push("/")
            //global.hist.push("/admin/dashboard")
          })
      })
  }
}



const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 100,
    height: 50,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(50px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 48,
    height: 48,
  },
  track: {
    borderRadius: 50 / 2,
    border: `0px solid ${theme.palette.grey[400]}`,
    backgroundColor: "red",
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch2
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});