import { CircularProgress, Grid, MenuItem, Select } from '@material-ui/core';
import { parse } from 'date-fns';
import React from 'react'

const addDays = function (sdate, days) {
    var date = new Date(sdate);
    date.setDate(date.getDate() + days);
    return date;
}

export default function Holidayoverview(props) {
    const [year, setYear] = React.useState(0);
    const [data, setData] = React.useState(undefined);
    const [years, setYears] = React.useState([]);
    const [holidaysbylaw, setHolidaysbylaw] = React.useState(undefined);


    const handleChange = (event) => {
        setYear(event.target.value);
    };

    if (data == undefined) {
        let url = 'https://' + global.localip + ':3005/getNotDeclinedHolidaysbyId/' + props.userid;
        fetch(url)
            .then(response => response.json())
            .then(responseJSON => {
                setTimeout(() => {
                    setData(responseJSON);
                    var years2 = [];
                    for (let i = 0; i < responseJSON.length; i++) {
                        const element = responseJSON[i];
                        var year = parseInt(JSON.parse(element.holidayobject).selection0.startDate.substring(0, 4));

                        if (!years2.includes(year)) {
                            years2.push(year);
                        }
                    }
                    years2.sort();
                    setYears(years2);
                }, 1000);
            });
        return <Loading></Loading>;
    } else if (holidaysbylaw == undefined) {
        if (years.length === 0) {
            return "Kein Eintrag vorhanden."
        }
        try {
            var url4 = "https://feiertage-api.de/api/?jahr=";
            var url4_2 = "&nur_land=HH";
            var startyear = years[0];
            var endyear = years[years.length - 1];
            var did = 0;
            var temphbylaw = [];
            for (let i = startyear; i <= endyear; i++) {
                fetch(url4 + i + url4_2)
                    .then(response => response.json())
                    .then(result4 => {
                        temphbylaw[i] = result4;
                        did++;

                        if (did == (endyear - startyear + 1)) {
                            setHolidaysbylaw(temphbylaw);
                        }
                    })
            }
        } catch (error) {

        }

        return <Loading></Loading>;
    } else if (year === 0) {
        return (
            <>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={year}
                    onChange={handleChange}
                >{years.map(function (item, i) {
                    return <MenuItem value={item}>{item}</MenuItem>
                })}
                </Select>
                Wähle ein Jahr aus um forzufahren.
            </>
        )
    }

    function isLawHoliday(date) {
        var hthisyear = holidaysbylaw[date.getFullYear()];
        for (const key in hthisyear) {
            const element = hthisyear[key];
            if (element.datum == date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)) {
                return [true, key];
            }
        }
        return [false, ""];
    }
    function shouldWork(date) {
        var worktimeobj = global.WorktimeObjectAt(date, props.userid);
        console.log(worktimeobj);
        for (let i = 0; i < worktimeobj.length; i++) {
            const element = worktimeobj[i];
            if (parseInt(element.day) === date.getDay()) {
                console.log(element.day);
                console.log(date.getDay());
                return true;
            }
        }
        return false;
    }
    function getDates(startDate, stopDate) {
        var dateArray = new Array();
        var currentDate = startDate;
        while (currentDate <= stopDate) {
            dateArray.push(new Date(currentDate));
            currentDate = addDays(currentDate, 1);
        }
        return dateArray;
    }

    var filtereddata = [];
    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        var start = new Date(JSON.parse(element.holidayobject).selection0.startDate);
        if (start.getFullYear() === year) {
            filtereddata.push(element);
        }
    }

    var worktimesdata = global.WorktimeObjectAt(new Date(JSON.parse(filtereddata[0].holidayobject).selection0.startDate), props.userid);
    var totaldays = parseInt(worktimesdata[worktimesdata.length - 1].holidays);

    var totalnumdays = 0;
    return (
        <div>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                onChange={handleChange}
            >{years.map(function (item, i) {
                return <MenuItem value={item}>{item}</MenuItem>
            })}
            </Select>
            <br></br>
            <h3>Urlaubsplan {year}</h3>
            <Grid container>
                <Grid item xs={3}>
                    Resturlaub
                    <br></br>
                    Neuer Urlaub:
                    <br></br>
                    Gesamt:
                </Grid>
                <Grid item xs={1}>
                    ?
                        <br></br>
                    {totaldays}
                    <br></br>
                    {totaldays}
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={2}>beantragt</Grid>
                <Grid item xs={2}>genehmigt</Grid>
                {filtereddata.map(function (dataset, i) {
                    var hobj = JSON.parse(dataset.holidayobject);
                    var startDate = new Date(hobj.selection0.startDate);
                    var endDate = new Date(hobj.selection0.endDate);

                    var datesbetween = getDates(startDate, endDate);
                    var numdays = 0;
                    for (let i = 0; i < datesbetween.length; i++) {
                        const element = datesbetween[i];
                        if (!isLawHoliday(element)[0]) {
                            console.log(element);
                            console.log(shouldWork(element));
                            if (shouldWork(element)) {
                                numdays++;
                                totalnumdays++;
                            }
                        }
                    }
                    if (hobj.selection0.startDate === hobj.selection0.endDate) {
                        return <>
                            <Grid item xs={3}>
                                {startDate.getDate()}.{startDate.getMonth() + 1}.{startDate.getFullYear()}
                            </Grid>
                            <Grid item xs={1}>
                                -{numdays} Tage
                        </Grid>
                            <Grid item xs={4}>

                            </Grid>
                            <Grid item xs={2}>
                                ✓
                        </Grid>
                            <Grid item xs={2}>
                                {(dataset.status === 1 ? '✓' : '')}
                            </Grid>
                        </>
                    } else {
                        return <>
                            <Grid item xs={3}>
                                {startDate.getDate()}.{startDate.getMonth() + 1}.{startDate.getFullYear()} -
                                {endDate.getDate()}.{endDate.getMonth() + 1}.{endDate.getFullYear()}
                            </Grid>
                            <Grid item xs={1}>
                                -{numdays} Tage
                        </Grid>
                            <Grid item xs={4}>

                            </Grid>
                            <Grid item xs={2}>
                                ✓
                        </Grid>
                            <Grid item xs={2}>
                                {(dataset.status === 1 ? '✓' : '')}
                            </Grid>
                        </>
                    }
                })}
                <Grid item xs={12}>
                    Verbleibend: {totaldays - totalnumdays}
                </Grid>
            </Grid>
        </div>
    )
}

function Loading() {
    return (
        <>
            <CircularProgress></CircularProgress>
        </>
    )
}