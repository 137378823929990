import { Accordion, AccordionDetails, AccordionSummary, Switch } from '@material-ui/core';
import { ExpandMore, Undo } from '@material-ui/icons';
import React from 'react'

var axios = require("axios");

export default function StampLocation() {
    const [users, setUsers] = React.useState(undefined);
    const [onlyWebinterface, setOnlyWebinterface] = React.useState(false); //Nur Webinterface = true
    const [onlyfirstofDay, setonlyfirstofDay] = React.useState(true); //Nur erster am Tag = true
    if (window.loggedinadmin !== 1) {
        return (
            <div>
                Du musst Admin sein.
            </div>
        )
    }

    if (users == undefined) {
        var url3 = "https://" + global.localip + ":3005/getUsers";
        fetch(url3, { 
          method: 'POST', 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ sessionid: localStorage.getItem("sessionid") }) 
        })
        .then(response => response.json())
        .then(result => {
            setUsers(result);
        })


        return "Loading Users...";
    }


    return (
        <div>
            Nur Stempelungen des Webinterfaces anzeigen
            <Switch checked={onlyWebinterface} onChange={() => (setOnlyWebinterface(!onlyWebinterface))}>
            </Switch>
            Nur erste Stempelungen des Tages anzeigen
            <Switch checked={onlyfirstofDay} onChange={() => (setonlyfirstofDay(!onlyfirstofDay))}>
            </Switch>
            {users.map((user, key) => {
                return (<ByUser key={key} user={user} onlyWebinterface={onlyWebinterface} onlyfirstofDay={onlyfirstofDay}></ByUser>);
            })}
        </div>
    )
}

function ByUser(props) {
    const [data, setData] = React.useState(undefined);
    var user = props.user;

    if (data == undefined) {
        var url3 = "https://" + global.localip + ":3005/getStamp/" + user.id;
        axios.get(url3)
            .then(response => {
                var data2 = response.data;
                setData(data2);
            })
            .catch(e => console.log(e))


        return "Loading Stamps...";
    }

    if (data.length == 0) {
        return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore></ExpandMore>}>
                    {user.name} {user.surname}
                </AccordionSummary>
                <AccordionDetails>
                    Keine Stempelungen gefunden
            </AccordionDetails>
            </Accordion>
        )
    }

    var dataaccordion = [];
    if (props.onlyWebinterface) {
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.stationstamp === 0 && element.inorout === 1) {
                dataaccordion.push(element);
            }
        }
    } else {
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.inorout === 1) {
                dataaccordion.push(element);
            }
        }
    }

    dataaccordion.reverse();

    if (props.onlyfirstofDay) {
        var laststamp = new Date(0);
        for (let i = 0; i < dataaccordion.length; i++) {
            const element = dataaccordion[i];
            var stamp = new Date(element.stamp);
            if (laststamp.getFullYear() === stamp.getFullYear() && laststamp.getMonth() === stamp.getMonth() && laststamp.getDate() === stamp.getDate()) {
                dataaccordion.splice(i, 1);
                i = i - 1;
            }
            laststamp = stamp;
        }
    }

    dataaccordion.reverse();
    
    return (
        <>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore></ExpandMore>}>
                    {user.name} {user.surname}
                </AccordionSummary>
                <AccordionDetails>
                    {dataaccordion.map((entry, key) => {
                        //return (<><br></br>{JSON.stringify(entry)}</>);
                    return (<><br></br>{new Date(entry.stamp).toLocaleString()} : Eingestempelt via 
                    {entry.stationstamp === 1 && <> Station</> }
                    {entry.stationstamp === 0 && <> Webinterface</> }
                    </>);
                    })}
                </AccordionDetails>
            </Accordion>
        </>
    )
}
