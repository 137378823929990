import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import UserSettings from "components/Graphs/UserSettings.js"
import MaterialTable from 'material-table'


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const classes = useStyles();
  if(window.loggedinadmin === undefined){
    alert("Du bist nicht eingeloggt oder besitzt nicht die erforderlichen Rechte.")
    window.hist.push("/admin/dashboard")
    return (<></>);
  } else if(window.loggedinadmin === 0){
    alert("Du besitzt leider nicht die erforderlichen Rechte.")
    window.hist.push("/admin/dashboard")
    return (<></>);
  }
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{zIndex: "0"}}>
        <Card>
          <CardHeader color="success">
            <h4 className={classes.cardTitleWhite}>Angemeldete Benutzer</h4>
            <p className={classes.cardCategoryWhite}>
              Hier siehst du alle Benutzer der verschiedenen Firmen.
            </p>
          </CardHeader>
          <CardBody>
            <MaterialTable
              options={{ detailPanelType: "single"}}
              columns={[
                { title: 'Vorname', field: 'name' },
                { title: 'Nachname', field: 'surname' },
                { title: 'Email', field: 'email' },
                { title: 'Team', field: 'team' },
                { title: 'Admin', field: 'admin' }
              ]}
              data={query =>
                new Promise((resolve, reject) => {
                  let url = 'https://' + global.localip + ':3005/getUsers'
                  fetch(url, { 
                    method: 'POST', 
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ sessionid: localStorage.getItem("sessionid") }) 
                  })
                    .then(response => response.json())
                    .then(result => {
                      var length = result.length;
                      result = result.slice(query.page*query.pageSize, query.page*query.pageSize+query.pageSize)
                      
                      resolve({
                        data: result,
                        page: query.page,
                        totalCount: length,
                      })
                    })
                })
              }
              detailPanel={rowData => {
                return (
                  <UserSettings id={rowData.id}></UserSettings>
                )
              }}
              title="Benutzer"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
