import React from 'react'
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import UserGraphs from 'components/Graphs/UserGraphs';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DetailsIcon from '@material-ui/icons/Details';
import GetAvatar from 'views/UserProfile/GetAvatar';

var holiday = "";
export default function TileView() {
    var [users, setUsers] = React.useState(undefined);
    var rows = [];

    if (users != undefined) {
        var arr = [];
        for (let i = 0; i < (users.length / 6); i++) {
            for (let j = 0; j < 6; j++) {
                var element = users[i * 6 + j];
                if (element != undefined) {
                    if (element.admin == 0) {
                        arr.push(element);
                    }
                }
            }
        }
        for (let i = 0; i < users.length; i++) {
            const element = users[i];
            global.fetchWorktimesbyUserid(element.id)
        }
        rows.push(arr);
    }

    if (window.loggedinadmin == undefined) {
        alert("Du bist nicht eingeloggt oder besitzt nicht die erforderlichen Rechte.")
        window.hist.push("/admin/dashboard")
        return (<></>);
    } else if (window.loggedinadmin == 0) {
        alert("Du besitzt leider nicht die erforderlichen Rechte.")
        window.hist.push("/admin/dashboard")
        return (<></>);
    }

    if (users == undefined) {
        let url = 'https://' + global.localip + ':3005/getUsers'
        fetch(url, { 
          method: 'POST', 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ sessionid: localStorage.getItem("sessionid") }) 
        })
            .then(response => response.json())
            .then(result => {
                var url4 = "https://feiertage-api.de/api/?jahr=";
                var url4_2 = "&nur_land=HH";
                var today = new Date();
                var year = today.getFullYear();
                fetch(url4 + year + url4_2)
                    .then(response => response.json())
                    .then(result4 => {
                        for (const day in result4) {
                            const element = result4[day].datum;
                            const parts = element.split("-");
                            if (parseInt(parts[0]) == today.getFullYear() && parseInt(parts[1]) == (today.getMonth() + 1) && parseInt(parts[2]) == today.getDate()) {
                                holiday = day;
                            }
                        }
                        setUsers(result);

                    })
            })
    }

    if (rows.length == 0) {
        return (
            <div>
                Loading
            </div>
        )
    }
    return (
        <div style={{ overflow: "hidden", marginBottom: "50px" }}>
            <h3>Kachelansicht über alle Benutzer</h3>
            {rows.map((row, key) => {
                return (
                    <Grid container spacing={7} key={key}>
                        <TileRow row={row}></TileRow>
                    </Grid>
                );

            })}
        </div>
    )
}


function TileRow(props) {
    var row = props.row;
    return (
        <>
            {row.map((item, key) => {
                return (
                    <Tile key={key} data={item}></Tile>
                );

            })}
        </>
    )
}

function Tile(props) {
    var [todaydata, setData] = React.useState(undefined);
    var [worktimes, setWorktimes] = React.useState(undefined);
    var data = props.data;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    if (data == undefined) {
        return (
            <>
            </>
        )
    }


    if (todaydata == undefined) {
        let url = 'https://' + global.localip + ':3005/getToday/' + data.id;
        fetch(url)
            .then(response => response.json())
            .then(result => {
                let url = 'https://' + global.localip + ':3005/getWorktimesbyId/' + data.id;
                fetch(url)
                    .then(response => response.json())
                    .then(result2 => {
                        setData(result);
                        setWorktimes(result2)
                    })
            })
        return (
            <></>
        )
    }

    if (worktimes == undefined) {
        return (
            <></>
        )
    }

    var worktimeobject;
    if (worktimes.length != 0) {
        worktimeobject = JSON.parse(worktimes[0].worktimeobject);
    }

    var today = new Date();
    var workingtimesday;

    if (worktimeobject != undefined) {
        for (let i = 0; i < worktimeobject.length; i++) {
            if (worktimeobject[i].day == today.getDay()) {
                workingtimesday = worktimeobject[i];
                break;
            }
        }
    }

    if(holiday != ""){
        return (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <Box borderRadius={16} border={5} borderColor="#e3f2fd" boxShadow={10} bgcolor="#757575" color="primary.contrastText" p={2}>
                    <div style={{ textAlign: "center" }}>
                        <GetAvatar id={data.id} color="#e3f2fd"></GetAvatar>
                        <h4>{data.name}<br></br>{data.surname}</h4>
                        Heute ist ein Feiertag<br></br>
                        {holiday}
                        <ChartDialog id={data.id}></ChartDialog>
                    </div>
                </Box>
            </Grid>
        )
    }
    if (workingtimesday == undefined) {
        return (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <Box borderRadius={16} border={5} borderColor="#e3f2fd" boxShadow={10} bgcolor="#757575" color="primary.contrastText" p={2}>
                    <div style={{ textAlign: "center" }}>
                        <GetAvatar id={data.id} color="#e3f2fd"></GetAvatar>
                        <h4>{data.name}<br></br>{data.surname}</h4>
                        Heute keine Arbeitszeit<br></br>
                        -
                        <ChartDialog id={data.id}></ChartDialog>
                    </div>
                </Box>
            </Grid>
        )
    } else {
        var firststamp;
        todaydata.reverse();
        for (let i = 0; i < todaydata.length; i++) {
            if (todaydata[i].inorout == 1) {
                firststamp = todaydata[i];
                break;
            }
        }
        var toarrive = new Date();
        toarrive.setHours(workingtimesday.start.split(":")[0])
        toarrive.setMinutes(workingtimesday.start.split(":")[1])
        toarrive.setSeconds(0);
        if (firststamp == undefined) {
            var stampin = new Date();
            var secondsdiff = (stampin - toarrive) / 1000;
            if (secondsdiff > 0) {
                return (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                        <Box borderRadius={16} border={5} borderColor="#c62828" boxShadow={10} bgcolor="#757575" color="primary.contrastText" p={2}>
                            <div style={{ textAlign: "center" }}>
                                <GetAvatar id={data.id} color="#c62828"></GetAvatar>
                                <h4>{data.name}<br></br>{data.surname}</h4>
                                Um {startToPretty(workingtimesday.start)} Uhr erwartet.<br></br>
                                Bereits {secondstoHHMMSS(secondsdiff)} zu spät.
                            <ChartDialog id={data.id}></ChartDialog>
                            </div>
                        </Box>
                    </Grid>
                )
            } else {
                return (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                        <Box borderRadius={16} border={5} borderColor="#4caf50" boxShadow={10} bgcolor="#757575" color="primary.contrastText" p={2}>
                            <div style={{ textAlign: "center" }}>
                                <GetAvatar id={data.id} color="#4caf50"></GetAvatar>
                                <h4>{data.name}<br></br>{data.surname}</h4>
                                Um {startToPretty(workingtimesday.start)} Uhr erwartet.<br></br>
                                -
                                <ChartDialog id={data.id}></ChartDialog>
                            </div>
                        </Box>
                    </Grid>
                )
            }
        }
        var stampin = new Date(firststamp.stamp);

        var secondsdiff = (stampin - toarrive) / 1000;

        if (secondsdiff > 0) {
            return (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                    <Box borderRadius={16} border={5} borderColor="#ff9800" boxShadow={10} bgcolor="#757575" color="primary.contrastText" p={2}>
                        <div style={{ textAlign: "center" }}>
                            <GetAvatar id={data.id} color="#ff9800"></GetAvatar>
                            <h4>{data.name}<br></br>{data.surname}</h4>
                            Ankunft: {startToPretty(stampin.getHours() + ":" + stampin.getMinutes())} Uhr<br></br>
                            Verspätung: {secondstoHHMMSS(secondsdiff)}
                            <ChartDialog id={data.id}></ChartDialog>
                        </div>
                    </Box>
                </Grid>
            )
        } else {
            return (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                    <Box borderRadius={16} border={5} borderColor="#4caf50" boxShadow={10} bgcolor="#757575" color="primary.contrastText" p={2}>
                        <div style={{ textAlign: "center" }}>
                            <GetAvatar id={data.id} color="#4caf50"></GetAvatar>
                            <h4>{data.name}<br></br>{data.surname}</h4>
                            Pünktlich.<br></br>
                            Ankunft: {startToPretty(stampin.getHours() + ":" + stampin.getMinutes())} Uhr<br></br>
                            <ChartDialog id={data.id}></ChartDialog>
                        </div>
                    </Box>
                </Grid>
            )
        }
    }
}


function secondstoHHMMSS(totalSeconds) {
    var sec_num = totalSeconds;
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    seconds = parseInt(seconds);
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
}

function startToPretty(start) {
    var hours = start.split(":")[0];
    var minutes = start.split(":")[1];
    if (hours.length == 1) {
        hours = "0" + hours;
    }
    if (minutes.length == 1) {
        minutes = "0" + minutes;
    }

    return hours + ":" + minutes;
}


function SimpleDialog(props) {
    const { onClose, open, id } = props;

    const handleClose = () => {
        onClose(0);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };
    if (!open) {
        return (<></>)
    }
    return (
        <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <AppBar>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">
                        Benutzerübersicht
                    </Typography>
                </Toolbar>
            </AppBar>
            <div style={{ margin: "40px" }}></div>
            <UserGraphs id={id}></UserGraphs>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

function ChartDialog(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClickOpen} variant="contained" color="primary" startIcon={<DetailsIcon />} endIcon={<DetailsIcon />}>
                Details anzeigen
            </Button>
            <SimpleDialog open={open} onClose={handleClose} id={props.id} />
        </div>
    );
}
