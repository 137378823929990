import React from "react";
import MaterialTable from 'material-table';


function secondstoHHMMSS(totalSeconds) {
    var sec_num = totalSeconds;
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    seconds = parseInt(seconds);
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}

export default function TableList(props) {
    let dailystamps = props.dailystamps;
    let monthlystamps = props.monthlystamps;
    let annuallystamps = props.annuallystamps;
    
    var year = new Date().getFullYear().toString();

    var allyeardata = [];
    var yeardata = [];
    var monthdata = [];
    var daydata = [];

    for (let i = 0; i < annuallystamps.length; i++) {
        const element = annuallystamps[i];
        
        var year2 = element.start.getFullYear();
        var allyeardataobj = {year: year2}

        var found = false;
        for (let i = 0; i < allyeardata.length; i++) {
            const element = allyeardata[i];
            if(element.year === year2){
                found = true;
            }
        }
        
        if(!found){
            allyeardata.push(allyeardataobj);
        }
    }
    
    function doYearData(rowData) {
        yeardata = [];
        year = rowData.year.toString();
        for (let i = 0; i < annuallystamps.length; i++) {
            const element = annuallystamps[i];
            if(element.start.getFullYear() === rowData.year){
                const month = element.start.getMonth() + 1;
                let startstring = element.start.getDate() + ". " + (element.start.getMonth() + 1) + ". " + element.start.getFullYear();
                let endstring = element.end.getDate() + ". " + (element.end.getMonth() + 1) + ". " + element.end.getFullYear();
                yeardata.push({ month: month, hours: secondstoHHMMSS(element.seconds), start: startstring, end: endstring });
            }
        }
    }
    function doMonthData(rowData) {
        var month = rowData.month;
        var year = parseInt(rowData.start.substr(rowData.start.length - 4));

        monthdata = [];
        for (let i = 0; i < monthlystamps.length; i++) {
            const element = monthlystamps[i];

            const day = element.start.getDate();

            var days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
            var d = element.start;
            var dayName = days[d.getDay()];

            if (year === element.start.getFullYear() && month === (element.start.getMonth()+1)) {
                let startstring = element.start.getDate() + ". " + (element.start.getMonth() + 1) + ". " + element.start.getFullYear() + " " + ("0" + element.start.getHours()).slice(-2) + ":" + ("0" + element.start.getMinutes()).slice(-2);
                let endstring = element.end.getDate() + ". " + (element.end.getMonth() + 1) + ". " + element.end.getFullYear() + " " + ("0" + element.end.getHours()).slice(-2) + ":" + ("0" + element.end.getMinutes()).slice(-2);
                monthdata.push({ day: day, weekday: dayName, hours: secondstoHHMMSS(element.seconds), start: startstring, end: endstring })
            }
        }
    }
    function doDayData(rowData) {
        daydata = [];
        for (let i = 0; i < dailystamps.length; i++) {
            var startparts = rowData.start.split(" ");

            var rowDate = new Date(startparts[2], startparts[1] - 1, startparts[0])
            const element = dailystamps[i];


            if (rowDate.getFullYear() === element.start.getFullYear() && rowDate.getMonth() === element.start.getMonth() && rowDate.getDate() === element.start.getDate()) {

                let startstring = element.start.getDate() + ". " + (element.start.getMonth() + 1) + ". " + element.start.getFullYear() + " " + ("0" + element.start.getHours()).slice(-2) + ":" + ("0" + element.start.getMinutes()).slice(-2);
                let endstring = element.end.getDate() + ". " + (element.end.getMonth() + 1) + ". " + element.end.getFullYear() + " " + ("0" + element.end.getHours()).slice(-2) + ":" + ("0" + element.end.getMinutes()).slice(-2);
                daydata.push({ minutes: secondstoHHMMSS(element.seconds), start: startstring, end: endstring })
            }
        }

    }

    function monthnamebyint(num) {
        var months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
        return months[num];
    }

    if (allyeardata.length === 0) {
        return (
          <div>
            Keine Daten zum Anzeigen vorhanden.
          </div>
        )
      }

    return (
        <> <MaterialTable
        columns={[
            { title: 'Jahr', field: 'year' },
        ]}
        data={allyeardata}
        options={{detailPanelType: "single", exportButton: true}}
        title="Tabellarische Stundenübersicht"
        detailPanel={rowData0 => {
            doYearData(rowData0);
            return(
                <MaterialTable
                columns={[
                    { title: 'Monat', field: 'month' },
                    { title: 'Stunden', field: 'hours' },
                    { title: 'Anfang', field: 'start' },
                    { title: 'Ende', field: 'end' }
                ]}
                data={yeardata}
                options={{exportButton: true}}
                detailPanel={rowData => {
                    doMonthData(rowData)
                    return (
                        <>
                            <MaterialTable
                                columns={[
                                    { title: 'Tag', field: 'day' },
                                    { title: 'Wochentag', field: 'weekday' },
                                    { title: 'Stunden', field: 'hours' },
                                    { title: 'Anfang', field: 'start' },
                                    { title: 'Ende', field: 'end' }
                                ]}
                                data={monthdata}
                                options={{exportButton: true}}
                                detailPanel={rowData2 => {
                                    doDayData(rowData2);
                                    return (
                                        <>
                                            <MaterialTable
                                                columns={[
                                                    { title: 'Tag', field: 'day' },
                                                    { title: 'Stunden', field: 'minutes' },
                                                    { title: 'Anfang', field: 'start' },
                                                    { title: 'Ende', field: 'end' }
                                                ]}
                                                data={daydata}
                                                title={rowData2.day + ". " + rowData.month + ". " + year + " (" + rowData2.weekday + ")"}
                                                options={{exportButton: true, paging: false}}
                                            />
                                            <br></br>
                                        </>
                                    )
                                }}
                                title={monthnamebyint(rowData.month - 1) + " " + year}
                            />
                        </>
                    )
                }}
                title={year}
            />
            )
        }}
        />
            
        </>
    );
}
