import Person from "@material-ui/icons/Person";
import DateRange from "@material-ui/icons/DateRange";
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import TileView from "views/TileView/TileView.js";
import GroupIcon from '@material-ui/icons/Group';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import LoginPage from "views/LoginPage/LoginPage.js";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HolidaysandSick from "views/Holidays/HolidayandSick.js";
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AddUser from "views/AddUser/AddUser.js";
import Sick from "views/Sick/Sick.js";
import MissedDays from "views/MissedDays/MissedDays.js";
import AddWork from "views/AddWork/AddWork.js";
import AddWorkAdmin from "views/AddWork/AddWorkAdmin.js";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import QueueIcon from '@material-ui/icons/Queue';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import { LocationOn } from "@material-ui/icons";
import StampLocation from "views/StampLocation/StampLocation";

const dashboardRoutes = [
  {
    path: "/login",
    name: "Ausloggen",
    icon: ExitToAppIcon,
    component: LoginPage,
    layout: "/admin"
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/user",
    name: "Dein Profil",
    icon: Person,
    component: UserProfile,
    layout: "/admin"
  },
  {
    path: "/daysettings",
    name: "Urlaub",
    icon: DateRange,
    component: HolidaysandSick,
    layout: "/admin"
  },
  {
    path: "/tiles",
    name: "Benutzerübersicht",
    icon: ViewModuleIcon,
    component: TileView,
    layout: "/admin"
  },
  {
    path: "/table",
    name: "Benutzer bearbeiten",
    icon: GroupIcon,
    component: TableList,
    layout: "/admin"
  },
  {
    path: "/adduser",
    name: "Benutzer hinzufügen",
    icon: GroupAddIcon,
    component: AddUser,
    layout: "/admin"
  },
  {
    path: "/sick",
    name: "Krankentag nachtragen",
    icon: QueueIcon,
    component: Sick,
    layout: "/admin"
  },
  {
    path: "/missed",
    name: "Verpasste Arbeitstage",
    icon: NotInterestedIcon,
    component: MissedDays,
    layout: "/admin"
  },
  {
    path: "/addwork",
    name: "Arbeitszeit nachtragen",
    icon: AddAlarmIcon,
    component: AddWork,
    layout: "/admin"
  },
  {
    path: "/addworkadmin",
    name: "Arbeitszeit Anträge",
    icon: AddAlarmIcon,
    component: AddWorkAdmin,
    layout: "/admin"
  },
  {
    path: "/stamplocation",
    name: "Ort der Stempelungen",
    icon: LocationOn,
    component: StampLocation,
    layout: "/admin"
  }
];

export default dashboardRoutes;
