/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import LoginPage from "views/LoginPage/LoginPage.js";

// core components
import Admin from "layouts/Admin.js";
import "assets/css/material-dashboard-react.css?v=1.8.0";

const hist = createBrowserHistory();
window.hist = hist;
//global.localip = window.location.hostname;

hist.listen(location => {
  if (location.pathname !== "/login/login") {
    if(location.pathname === "/admin/login"){
      setTimeout(() => {
        hist.push("/login/login")
      }, 0);
    }
    if (window.loggedinid === undefined) {
      setTimeout(() => {
        hist.push("/login/login")
      }, 0);
    }
  }
})
hist.push("/login/login")

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/admin" component={Admin}/>
      <Route path="/login" component={LoginPage} />
      <Redirect from="/" to="/admin/dashboard" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
