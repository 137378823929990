import React from 'react'
//Tabs
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import WorkTimes from './WorkTimes.js';

import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import UserProfile from 'views/UserProfile/UserProfile.js';
import Sick from 'views/Sick/Sick.js';

let results = undefined;
let lastid = undefined;
let stamps = [];
let dailystamps = [];
let monthlystamps = [];
let annuallystamps = [];

export default class UserGraphs extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let url = 'https://' + global.localip + ':3005/getStamp/' + this.props.id
        if (lastid = undefined || lastid != this.props.id) {
            lastid = this.props.id;

            stamps = [];
            dailystamps = [];
            monthlystamps = [];
            annuallystamps = [];
            fetch(url)
                .then(response => response.json())
                .then(result => {
                    results = result;
                    //Stamps to Date Object
                    for (let i = 0; i < results.length; i++) {
                        const stamp = results[i];
                        stamp.stamp = stamp.stamp.replace("T", " ");
                        stamp.stamp = stamp.stamp.replace("Z", " ");
                        let dateTimeParts = stamp.stamp.split(/[- :]/);

                        dateTimeParts[1]--;
                        const dateObject = new Date(...dateTimeParts);
                        dateObject.setHours(dateObject.getHours() + 1);
                        stamps.push({ time: dateObject, inorout: stamp.inorout });
                    }
                    //Calculate, draw Graphs
                    //Stamps By Day (Every Stamp)
                    this.doDailyStamps();
                    //Stamp by Month (Every Day)
                    this.doMonthlyStamps();
                    //Stamps by Year (Every Month)
                    this.doAnnuallyStamps();
                    //Fore Rerender
                    this.forceUpdate();

                })
        }

        if (results == undefined) {
            return (
                <div>
                    Loading
                </div>
            )
        } else {
            return (
                <div>
                    <SimpleTabs userid={this.props.id}></SimpleTabs>
                </div>
            )
        }

    }


    doDailyStamps() {
        var laststamp;
        for (let i = 0; i < stamps.length; i++) {
            const element = stamps[i];
            if(element.time.getTimezoneOffset() == -60){
              element.time.setHours(element.time.getHours()-1);
            }
            if (laststamp == undefined) {
                //First, Check if still stamped in
                if (element.inorout == 1) {
                    var startStamp = element.time;
                    var endStamp = new Date();
                    var seconds = (endStamp - startStamp) / 1000;
                    var calculatedStamp = { start: startStamp, end: endStamp, seconds: seconds }
                    dailystamps.push(calculatedStamp);
                }
                laststamp = element;
            } else {
                //Get inorout=1
                if (element.inorout == 1) {
                    var startStamp = element.time;
                    var endStamp = laststamp.time;
                    var seconds = (endStamp - startStamp) / 1000;
                    var calculatedStamp = { start: startStamp, end: endStamp, seconds: seconds }
                    dailystamps.push(calculatedStamp);
                }

                laststamp = element;
            }
        }
    }

    doMonthlyStamps() {
        for (let i = 0; i < dailystamps.length; i++) {
            const element = dailystamps[i];
            let date = element.start;
            if (monthlystamps.length == 0) {
                newDay()
            } else {
                var laststamp = monthlystamps[monthlystamps.length - 1].start;
                if (laststamp.getFullYear() == date.getFullYear() && laststamp.getMonth() == date.getMonth() && laststamp.getDate() == date.getDate()) {
                    monthlystamps[monthlystamps.length - 1].start = element.start;
                    monthlystamps[monthlystamps.length - 1].seconds = monthlystamps[monthlystamps.length - 1].seconds + element.seconds;
                } else {
                    newDay();
                }
            }
            function newDay() {
                var startStamp = element.start;
                var endStamp = element.end;
                var secs = element.seconds;
                var calculatedStamp = { start: startStamp, end: endStamp, seconds: secs }
                monthlystamps.push(calculatedStamp);
            }
        }
    }

    doAnnuallyStamps() {

        for (let i = 0; i < monthlystamps.length; i++) {
            const element = monthlystamps[i];
            let date = element.start;
            if (annuallystamps.length == 0) {
                newMonth();
            } else {
                var laststamp = annuallystamps[annuallystamps.length - 1].start;
                if (laststamp.getFullYear() == date.getFullYear() && laststamp.getMonth() == date.getMonth()) {
                    annuallystamps[annuallystamps.length - 1].start = element.start;


                    annuallystamps[annuallystamps.length - 1].seconds = annuallystamps[annuallystamps.length - 1].seconds + element.seconds;
                } else {
                    newMonth();
                }
            }
            function newMonth() {
                var startStamp = element.start;
                var endStamp = element.end;
                var secs = element.seconds;
                var calculatedStamp = { start: startStamp, end: endStamp, seconds: secs }
                annuallystamps.push(calculatedStamp);
            }
        }


    }

}





//Tabs
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function SimpleTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid container>
                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader color="danger">
                            <h4 className={classes.cardTitle}>Arbeitszeiten</h4>
                            <p>Hier können Arbeitszeiten eingestellt werden.</p>
                        </CardHeader>
                        <CardBody>
                            <WorkTimes monthlystamps={monthlystamps} id={props.userid}></WorkTimes>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader color="success">
                            <h4 className={classes.cardTitle}>Nutzerprofil</h4>
                            <p>Hier kann der Nutzer bearbeitet werden.</p>
                        </CardHeader>
                        <CardBody>
                            <br></br>
                            <UserProfile id={props.userid}></UserProfile>
                        </CardBody>
                    </Card>
                </GridItem>
            </Grid>
        </>
    )
}