import React, { useState } from 'react'

import PersonIcon from '@material-ui/icons/Person';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function GetAvatar(props) {
    const [img, setImg] = useState(undefined);
    const [color, setColor] = useState("gray");
    var styles = {
        root: {
            textAlign: "center",
            height: "80px",
            zIndex: "99999"
        },
        large: {
            width: "80px",
            height: "80px",
            display: "inline-flex",
            border: '5px solid ' + color,
        },
    }

    let url = 'https://' + global.localip + ':3005/getStamp/' + props.id;
    fetch(url)
        .then(response => response.json())
        .then(result => {
            if (result.length === 0) {
                if (color !== "#e53935") {
                    setColor("#e53935");
                }
            } else {
                if (result[0].inorout === 0) {
                    if (color !== "#e53935") {
                        setColor("#e53935");
                    }
                } else {
                    if (color !== "#43a047") {
                        setColor("#43a047");
                    }
                }
            }
        })
    if (img === undefined) {
        let url2 = 'https://' + global.localip + ':3005/getPic/' + props.id;
        if (global.allprofilepics === undefined) {
            global.allprofilepics = {};
        }

        if (global.allprofilepics[props.id] === undefined) {
            fetch(url2)
                .then(response => response.json())
                .then(result2 => {
                    if (result2.length > 0) {
                        global.allprofilepics[props.id] = result2[0].imgcontent;
                        setImg(result2[0].imgcontent)
                    }
                })
        } else {
            setImg(global.allprofilepics[props.id]);
        }
    }

    const useStyles = makeStyles((theme) => (styles));
    const classes = useStyles();
    if (img === undefined) {
        return (
            <div className={classes.root}>
                <Avatar className={classes.large}>
                    <PersonIcon></PersonIcon>
                </Avatar>
                <br></br>
            </div>
        )
    } else {
        return (
            <div className={classes.root}>
                <Avatar src={img} className={classes.large}>
                </Avatar>
            </div>
        )
    }
}
