import React from 'react';
import { addDays } from 'date-fns';
import { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import Grid from '@material-ui/core/Grid';
import { Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import MaterialTable from 'material-table';
import Button2 from "components/CustomButtons/Button.js";
var axios = require("axios");

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

var refetch = false;

export default function Sick(props) {
    var id;
    const [state, setState] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);
    if(props.userid != undefined){
        id = props.userid;
    } else {
        id = window.loggedinid;
    }
    

    const [state2, setState2] = React.useState({ open: false, msg: "Test", severity: "" });

    const [data, setData] = useState(undefined);
    if (data == undefined || refetch) {
        refetch = false;
        let url = 'https://' + global.localip + ':3005/getSickDate/' + id;
        fetch(url)
            .then(response => response.json())
            .then(result => {
                var data2 = [];
                for (let i = 0; i < result.length; i++) {
                    const element = result[i];
                    var start = new Date(element.start);
                    var end = new Date(element.end);

                    data2.push({ start: start, end: end, id: element.id })
                }
                setData(data2);
            })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState2({ open: false, msg: "", severity: "success" });
    };

    return (
        <div>
            <Snackbar open={state2.open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={state2.severity} >
                    {state2.msg}
                </Alert>
            </Snackbar>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <DateRangePicker
                        onChange={item => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" color="primary" onClick={() => {
                        
                        if (state[0].startDate != null && state[0].endDate != null) {
                            setState2({ open: true, msg: "Krankentag wird eingetragen....", severity: "warning" });
                            setState([
                                {
                                    startDate: null,
                                    endDate: null,
                                    key: 'selection'
                                }
                            ]);
                            var start = state[0].startDate;
                            var end = state[0].endDate;
                            var data = { start: start, end: end }
                            let url = 'https://' + global.localip + ':3005/insertSickDate/' + id;
                            axios.post(url, data)
                                .then(response => {
                                    refetch = true;
                                    setState2({ open: true, msg: "Krankentag eingetragen - vom: " + start.getDate() + ". " + (start.getMonth() + 1) + ". " + start.getFullYear() + " bis: " + end.getDate() + ". " + (end.getMonth() + 1) + ". " + end.getFullYear(), severity: "success" });
                                    setState([
                                        {
                                            startDate: null,
                                            endDate: null,
                                            key: 'selection'
                                        }
                                    ]);
                                })
                                .catch(e => console.log(e))
                        } else {
                            setState2({ open: true, msg: "Krankentag(e) konnte(n) nicht eingetragen werden. Bitte überprüf die Eingabe.", severity: "error" });
                        }
                    }}>Krankentag nachtragen</Button>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        columns={[
                            { title: 'Anfang', field: 'start', type: "date" },
                            { title: 'Ende', field: 'end', type: "date" },
                            { title: 'Löschen', field: 'delete', render: rowData => <DeleteButton rowData={rowData} data={data} setData={setData} setState2={setState2}/> },
                        ]}
                        data={data}
                        title="Krankentage">
                    </MaterialTable>
                </Grid>
            </Grid>
        </div>
    )
}

function DeleteButton(props) {
    return (
        <>
            <Button2 color="warning" onClick={() => {
                let url = 'https://' + global.localip + ':3005/deleteSickDate/' + props.rowData.id;
                fetch(url)
                    .then(response => response.json())
                    .then(result => {
                        props.setData(undefined);
                        props.setState2({ open: true, msg: "Eintrag gelöscht.", severity: "success" });
                    })
            }}>Löschen</Button2>
        </>
    )
}