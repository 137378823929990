import React from 'react'
import MaterialTable from 'material-table'
const addDays = function (sdate, days) {
    var date = new Date(sdate);
    date.setDate(date.getDate() + days);
    return date;
}

var collecteddates = [];
var holidaysbylaw = {};

export default class Lateness extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        global.worktimesdata = undefined;
    }

    render() {
        if (global.worktimesdata == undefined || holidaysbylaw == {}) {
            global.fetchWorktimesbyUserid(this.props.id);
            let url = 'https://' + global.localip + ':3005/getWorktimesbyId/' + this.props.id;
            fetch(url)
                .then(response => response.json())
                .then(responseJSON => {
                    if (responseJSON.length == 0) {
                        global.worktimesdata = [];
                    } else {
                        global.worktimesdata = responseJSON;
                        global.worktimesdata = JSON.parse(global.worktimesdata[0].worktimeobject);

                        this.props.setWeekA(global.currentWorktimeObject(this.props.id)[global.worktimesdata.length - 2].week);
                        this.props.setHolidayA(global.currentWorktimeObject(this.props.id)[global.worktimesdata.length - 1].holidays);
                    }
                    let url = 'https://' + global.localip + ':3005/getWorktimesbyId/' + this.props.id;
                    fetch(url)
                        .then(response => response.json())
                        .then(responseJSON => {
                            if (responseJSON.length == 0) {

                            } else {
                                var url = "https://" + global.localip + ":3005/getNotDeclinedHolidaysbyId/" + this.props.id;
                                fetch(url)
                                    .then(response => response.json())
                                    .then(result2 => {
                                        function getDates(startDate, stopDate) {
                                            var dateArray = new Array();
                                            var currentDate = startDate;
                                            while (currentDate <= stopDate) {
                                                dateArray.push(new Date(currentDate));
                                                currentDate = addDays(currentDate, 1);
                                            }
                                            return dateArray;
                                        }
                                        collecteddates = [];
                                        for (let i = 0; i < result2.length; i++) {
                                            var element;
                                            var select;
                                            element = JSON.parse(result2[i].holidayobject);
                                            select = element.selection0;

                                            const start = new Date(select.startDate);
                                            const end = new Date(select.endDate);
                                            var alldates = getDates(start, end);
                                            for (let i = 0; i < alldates.length; i++) {
                                                const element = alldates[i];

                                                if (global.WorkingDaysAt(element, this.props.id).includes(element.getDay())) {
                                                    collecteddates.push(element);
                                                }

                                            }
                                        }

                                        try {
                                            var url4 = "https://feiertage-api.de/api/?jahr=";
                                            var url4_2 = "&nur_land=HH";
                                            var startyear = this.props.monthlystamps[this.props.monthlystamps.length - 1].start.getFullYear();
                                            var endyear = this.props.monthlystamps[0].end.getFullYear();
                                            var did = 0;
                                            for (let i = startyear; i <= endyear; i++) {
                                                fetch(url4 + i + url4_2)
                                                    .then(response => response.json())
                                                    .then(result4 => {
                                                        holidaysbylaw[i] = result4;
                                                        did++;

                                                        if (did == (endyear - startyear + 1)) {
                                                            this.forceUpdate();
                                                        }
                                                    })
                                            }
                                        } catch (error) {

                                        }
                                    })

                            }
                        })
                })
            return (
                <div>
                    Loading
                </div>
            )
        } else {
            var data = [];
            var workeddays = [];
            var lateamountthismonth = 0;
            var missedamount = 0;
            for (let i = 0; i < this.props.monthlystamps.length; i++) {
                const element = this.props.monthlystamps[i];
                var start = element.start;
                var day = new Date(0);
                day.setFullYear(start.getFullYear());
                day.setMonth(start.getMonth());
                day.setDate(start.getDate());
                workeddays.push(day.toString());
            }

            try {
                var firstday = new Date(0);
                firstday.setFullYear(this.props.monthlystamps[this.props.monthlystamps.length - 1].start.getFullYear());
                firstday.setMonth(this.props.monthlystamps[this.props.monthlystamps.length - 1].start.getMonth());
                firstday.setDate(this.props.monthlystamps[this.props.monthlystamps.length - 1].start.getDate());


                var thisday = new Date();
                const diffTime = Math.abs(thisday - firstday);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                var lastday = firstday;

                function isHoliday(date) {
                    for (let i = 0; i < collecteddates.length; i++) {
                        const element = collecteddates[i];
                        if (element.getFullYear() == date.getFullYear() && element.getMonth() == date.getMonth() && element.getDate() == date.getDate()) {
                            return true;
                        }
                    }
                    return false;
                }

                function isLawHoliday(date) {
                    var hthisyear = holidaysbylaw[date.getFullYear()];
                    for (const key in hthisyear) {
                        const element = hthisyear[key];
                        if (element.datum == date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)) {
                            return [true, key];
                        }
                    }
                    return [false, ""];
                }

                for (let i = 0; i < diffDays - 1; i++) {
                    lastday.setDate(lastday.getDate() + 1);
                    var workingdays = global.WorkingDaysAt(lastday, this.props.id);
                    var lh = isLawHoliday(lastday);

                    if (lh[0]) {
                        if (workingdays.includes(lastday.getDay())) {
                            //Anzeigen von Feiertagen in Verspätungen / Ausfall
                            //data.push({ date: new Date(lastday.getFullYear(), lastday.getMonth(), lastday.getDate()), minutes: lh[1], start: "-", arrival: "-" })
                        }
                    } else if (isHoliday(lastday)) {
                        if (workingdays.includes(lastday.getDay())) {
                            data.push({ date: new Date(lastday.getFullYear(), lastday.getMonth(), lastday.getDate()), minutes: "Urlaubstag", start: "-", arrival: "-" })
                        }
                    } else if (workeddays.includes(lastday.toString())) {
                        for (let i = 0; i < this.props.monthlystamps.length; i++) {
                            const element = this.props.monthlystamps[i];
                            if (element.start.getFullYear() == lastday.getFullYear() && element.start.getMonth() == lastday.getMonth() && element.start.getDate() == lastday.getDate()) {
                                for (let i2 = 0; i2 < global.WorktimeObjectAt(element.start, this.props.id).length; i2++) {
                                    const element2 = global.WorktimeObjectAt(element.start, this.props.id)[i2];
                                    if (parseInt(element2.day) === element.start.getDay()) {
                                        var start = element.start;
                                        var end = new Date(start);
                                        end.setHours(element2.start.split(":")[0])
                                        end.setMinutes(element2.start.split(":")[1])
                                        end.setSeconds(0);
                                        var seconds = (start.getTime() - end.getTime()) / 1000;
                                        if (seconds >= 59) {
                                            var today = new Date();
                                            if (today.getFullYear() == start.getFullYear() && today.getMonth() == start.getMonth()) {
                                                lateamountthismonth++;
                                            }
                                            data.push({ date: new Date(start.getFullYear(), start.getMonth(), start.getDate()), minutes: secondstoHHMMSS(seconds), start: ("0" + start.getHours()).slice(-2) + ":" + ("0" + start.getMinutes()).slice(-2), arrival: ("0" + end.getHours()).slice(-2) + ":" + ("0" + end.getMinutes()).slice(-2) })

                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let i2 = 0; i2 < global.WorktimeObjectAt(lastday, this.props.id).length; i2++) {
                            const element2 = global.WorktimeObjectAt(lastday, this.props.id)[i2];
                            if (element2.day != undefined && element2.day === lastday.getDay()) {
                                var start = lastday;
                                var end = new Date(start);
                                end.setHours(element2.start.split(":")[0])
                                end.setMinutes(element2.start.split(":")[1])
                                end.setSeconds(0);
                                var seconds = (end.getTime() - start.getTime()) / 1000;
                                data.push({ date: new Date(start.getFullYear(), start.getMonth(), start.getDate()), minutes: secondstoHHMMSS(seconds), start: "-", arrival: ("0" + end.getHours()).slice(-2) + ":" + ("0" + end.getMinutes()).slice(-2) })
                                if (start.getFullYear() == new Date().getFullYear()) {
                                    missedamount++;
                                }
                            }
                        }
                    }

                }

                this.props.setMissed(missedamount);
                this.props.setLate(lateamountthismonth);
            } catch (error) {

            }

            if (data.length == 0) {
                return (
                    <div>
                        Keine Daten zum Anzeigen vorhanden.
                    </div>
                )
            }

            return (
                <div style={{ maxWidth: "100%" }}>
                    <MaterialTable
                        columns={[
                            { title: 'Datum', field: 'date', type: 'date', defaultSort: "desc" },
                            { title: 'Verspätung / Verpasste Arbeitszeit', field: 'minutes' },
                            { title: 'Tatsächliche Ankunft', field: 'start' },
                            { title: 'Planmäßiger Arbeitsbeginn', field: 'arrival' }]}
                        data={data}
                        title="Verspätungen / Ausfall"
                    />
                </div>
            )
        }
    }
}

function secondstoHHMMSS(totalSeconds) {
    var sec_num = totalSeconds;
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    seconds = parseInt(seconds);
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
}

