import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Grid, Snackbar } from '@material-ui/core';
import Card from 'components/Card/Card';
import CardHeader from "components/Card/CardHeader.js";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MuiAlert from '@material-ui/lab/Alert';

var axios = require("axios");

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);

export default function AddUser() {
    const [state, setState] = React.useState({ open: false, msg: "Test", severity: "" });
    const classes = useStyles();
    if (window.loggedinadmin === 0) {
        return (<div></div>)
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState({ open: false, msg: "", severity: "" });
    };
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    return (
        <div style={{ marginTop: "130px" }}>
            <Snackbar open={state.open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={state.severity} >
                    {state.msg}
                </Alert>
            </Snackbar>
            <Card style={{zIndex: "0"}}>
                <CardHeader color="success">
                    <h4 className={classes.cardTitleWhite}>Nutzer hinzufügen</h4>
                    <p className={classes.cardCategoryWhite}>Hier kannst du einen neuen Nutzer anlegen</p>
                </CardHeader>
                <Grid container
                    direction="column"
                    style={{ margin: "30px", alignItems:"center" }}>
                    <Grid container item xs={12}>
                        <Grid item xs={3}>
                            <TextField
                                id="name"
                                label="Vorname"
                                alignItems="stretch"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="surname"
                                label="Nachname"
                                alignItems="stretch"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={3}>
                            <TextField
                                id="email"
                                label="E-Mail"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="team"
                                label="Team"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="admin"
                                label="Admin"
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={3}>
                            <TextField
                                id="pw1"
                                label="Passwort setzen"
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="pw2"
                                label="Passwort wiederholen"
                                type="password"
                            />
                        </Grid>
                    </Grid>
                    <Button color="primary" style={{marginTop: "30px", width: "85%"}} onClick={() => {
                        try {
                            if (document.getElementById("name").value !== "") {
                                if (document.getElementById("surname").value !== "") {
                                    if (document.getElementById("email").value !== "") {
                                        if (document.getElementById("team").value !== "") {
                                            if (document.getElementById("admin").value === "1" || document.getElementById("admin").value === "0") {
                                                if (document.getElementById("pw1").value === document.getElementById("pw2").value) {
                                                    var name = document.getElementById("name").value;
                                                    var surname = document.getElementById("surname").value;
                                                    var email = document.getElementById("email").value;
                                                    var team = document.getElementById("team").value;
                                                    var admin = document.getElementById("admin").value;
                                                    var pw1 = document.getElementById("pw1").value;
                                                    var data = { name: name, surname: surname, email: email, team: team, admin: admin, pw1: pw1 }
                                                    let url = 'https://' + global.localip + ':3005/addUser/';
                                                    axios.post(url, data)
                                                        .then(response => {
                                                            setState({ open: true, msg: "Du hast erfolgreich den Nutzer '" + name + " " + surname + "' angelegt", severity: "success" });
                                                            try {
                                                                document.getElementById("name").value = "";
                                                                document.getElementById("surname").value = "";
                                                                document.getElementById("email").value = "";
                                                                document.getElementById("team").value = "";
                                                                document.getElementById("admin").value = "";
                                                                document.getElementById("pw1").value = "";
                                                                document.getElementById("pw2").value = "";
                                                            } catch (error) {
                                                                
                                                            }
                                                        })
                                                        .catch(e => console.log(e))
                                                } else {
                                                    setState({ open: true, msg: "Überprüfe, ob die Passwörter übereinstimmen", severity: "error" });
                                                }
                                            } else {
                                                setState({ open: true, msg: "Lege fest ob der Nutzer Admin sein soll. 0 = nein, 1 = ja", severity: "error" });
                                            }
                                        } else {
                                            setState({ open: true, msg: "Bitte gib das Team ein", severity: "error" });
                                        }
                                    } else {
                                        setState({ open: true, msg: "Bitte gib eine E-Mail ein", severity: "error" });
                                    }
                                } else {
                                    setState({ open: true, msg: "Bitte gib einen Nachnamen ein", severity: "error" });
                                }
                            } else {
                                setState({ open: true, msg: "Bitte gib einen Vornamen ein", severity: "error" });
                            }
                        } catch (error) {
                            
                        }
                    }}>Nutzer hinzufügen</Button>
                </Grid>
            </Card>
        </div>
    )
}
