import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from 'assets/img/logo_inout.png'
import Background from 'assets/img/white-waves.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiAlert from '@material-ui/lab/Alert';

import { Snackbar } from '@material-ui/core';

var axios = require("axios")

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="http://ecknology.de">
                Ecknology GmbH
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

var alreadyclicked = false;
function login() {
    try {
        var email = document.getElementById("email").value;
        var pw = document.getElementById("password").value;
        let url = 'https://' + global.localip + ':3005/getUser/' + email;

        var data = { password: pw }
        axios.post(url, data)
            .then(response => {
                var result = response.data;
                if (result.length != 0) {
                    if (!alreadyclicked) {
                        alreadyclicked = true;
                        //document.getElementById("buttonlogin").disabled = true;
                        setTimeout(() => {
                            //document.getElementById("buttonlogin").style.display = "none";
                            alreadyclicked = false;
                        }, 2000);
                        let url = 'https://' + global.localip + ':3005/getStamp/' + result[0].id;
                        window.loggedinid = result[0].id;
                        global.fetchWorktimesbyUserid(window.loggedinid);
                        window.loggedinadmin = result[0].admin;
                        global.email = result[0].email;
                        window.loggedin = result[0];
                        localStorage.setItem('sessionid', result[0].session.sessionid);
                        fetch(url)
                            .then(response => response.json())
                            .then(result => {
                                if (result.length != 0) {
                                    window.laststamp = result[0];
                                    var inorout = result[0].inorout;
                                    if (inorout == 0) {
                                        global.inoroutbool = false;
                                    } else {
                                        global.inoroutbool = true;
                                    }
                                } else {
                                    global.inoroutbool = false;
                                }

                                if (window.loggedinadmin == 0) {
                                    window.hist.push("/admin/dashboard")
                                } else {
                                    window.hist.push("/admin/tiles")
                                }

                            })
                    }
                } else {
                    try {
                        document.getElementById("login-info").style.display = "block";
                        document.getElementById("login-info").innerHTML = "Falscher Benutzer und / oder falsches Passwort eingegeben.";
                        setTimeout(() => {
                            try {
                                document.getElementById("login-info").style.display = "none";
                            } catch (error) {

                            }
                        }, 5000);
                    } catch (error) {

                    }
                }
            })
            .catch(e => console.log(e))
    } catch (error) {

    }
}
function loginBySession() {
    try {
        let url = 'https://' + global.localip + ':3005/getSessionObjbyId/' + localStorage.getItem("sessionid");

        var data = {}
        axios.post(url, data)
            .then(response => {
                var result = response.data;
                if (result.length != 0) {
                    if (!alreadyclicked) {
                        alreadyclicked = true;
                        //document.getElementById("buttonlogin").disabled = true;
                        setTimeout(() => {
                            //document.getElementById("buttonlogin").style.display = "none";
                            alreadyclicked = false;
                        }, 2000);
                        let url = 'https://' + global.localip + ':3005/getStamp/' + result.userid;
                        window.loggedinid = result.userid;
                        global.fetchWorktimesbyUserid(window.loggedinid);
                        window.loggedinadmin = result.admin;
                        global.email = result.email;
                        localStorage.setItem('sessionid', result.sessionid);
                        fetch(url)
                            .then(response => response.json())
                            .then(result => {
                                if (result.length != 0) {
                                    window.laststamp = result[0];
                                    var inorout = result[0].inorout;
                                    if (inorout == 0) {
                                        global.inoroutbool = false;
                                    } else {
                                        global.inoroutbool = true;
                                    }
                                } else {
                                    global.inoroutbool = false;
                                }

                                if (window.loggedinadmin == 0) {
                                    window.hist.push("/admin/dashboard")
                                } else {
                                    window.hist.push("/admin/tiles")
                                }

                            })
                    }
                } else {
                    try {
                        document.getElementById("login-info").style.display = "block";
                        document.getElementById("login-info").innerHTML = "Deine Session ist abgelaufen.";
                        localStorage.setItem('sessionid', null);
                        setTimeout(() => {
                            try {
                                document.getElementById("login-info").style.display = "none";
                            } catch (error) {

                            }
                        }, 5000);
                    } catch (error) {

                    }
                }
            })
            .catch(e => console.log(e))
    } catch (error) {

    }
}

export default function SignIn() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({ open: false, msg: "Test", severity: "" });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (window.loggedinid != undefined) {
        window.loggedinid = undefined;
        window.loggedinadmin = undefined;
        //localStorage.setItem('sessionid', null);
        window.location.reload();
    }

    var pw = "";
    var email = "";
    if (localStorage.getItem("sessionid") != "null") {
        setTimeout(() => {
            loginBySession();
        }, 500);
    }

    return (
        <>
            <Snackbar open={state.open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={state.severity} >
                    {state.msg}
                </Alert>
            </Snackbar>
            <div style={{ backgroundImage: `url(${Background})`, position: "absolute", width: "100%", height: "100%", top: "0px", zIndex: "-10" }}></div>
            <div>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <img src={logo} style={{ maxWidth: "35vw", maxHeight: "20vw", padding: "50px" }}></img>
                        <Typography component="h1" variant="h5">
                            Anmelden
                    </Typography>
                        <form className={classes.form} noValidate onSubmit={(e) => {
                            e.preventDefault();
                            login();
                        }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-Mail Adresse"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                defaultValue={email}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Passwort"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                defaultValue={pw}
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Angemeldet bleiben"
                            />
                            <div id="login-info" style={{ display: "none" }}></div>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Login
                    </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2" onClick={handleClickOpen}>
                                        Passwort vergessen?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#" variant="body2" onClick={(e) => {
                                        e.preventDefault();
                                        alert("Bitte sprich Deinen Vorgesetzten an.")
                                    }}>
                                        {"Noch keinen Account?"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <Box mt={8}>
                        <Copyright />
                    </Box>
                </Container>
            </div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Passwort zurücksetzen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte geben sie ihre E-Mail im unterem Textfeld ein und drücke auf "Zurücksetzen" um ein neues Passwort zu beantragen.
          </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="mailpwreset"
                        label="Email Address"
                        type="email"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={(e) => {
                        fetch("https://" + global.localip + ":3005/forgotPassword/" + document.getElementById("mailpwreset").value)
                            .then(response => response.json())
                            .then(result => {

                                handleClose();
                                setState({ open: true, msg: "Passwort zurückgesetzt", severity: "success" });

                            })
                    }} color="primary">
                        Zurücksetzen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}